import {makeAutoObservable} from "mobx";
import {apiServiceV2} from "../../services/apiService";
import {globalStore} from "../../global.store";
import {sendMessageStore} from "../../modules/unityLayout/sendMessage.store";
import {format} from "date-fns";

class ReserveTablePopupsStore {
    reserveTable: number = 0;
    reserveTableStatus: string = "";

    guestCount: number =  1;
    tableStatus: string =  "Free";
    reserveDate: Date = new Date();

    constructor() {
        makeAutoObservable(this);
    }

    onReserve = (id:number) => {
        this.reserveTable = id
        this.getCurrentTableInfo(id)
    }

    setGuestCount = (guestCount: number) => {
        this.guestCount = guestCount;
    }

    changeGuestCount = (name: string, value: number) => {
        this.setGuestCount(value);
    }

    changeTableStatus = (name: string, value: string) => {
        this.setTableStatus(value);
    }

    setTableStatus = (value: string) => {
        this.tableStatus = value;
    }

    setReserveTable = (id: number) => {
        this.reserveTable = id;
    }

    setReserveDate = (reserveDate: Date) => {
        this.reserveDate = reserveDate;
    }

    changeDate = (values: any[]) => {
        if(values.length) this.setReserveDate(values[0])
    }

    getCurrentTableInfo = async (id: any) => {
        const response = await apiServiceV2.getAllTables();
        if(!response.error) {
            const tables = response.data.result.filter((table: any) => table.chair_id == id)
            if(tables.length) {
                this.setReserveDate(tables[0].reserved_date);
                this.setGuestCount(tables[0].people_count);
                this.setTableStatus("1")
                this.reserveTableStatus = "1";
            } else {
                this.setGuestCount(1);
                this.setTableStatus("0");
                this.reserveTableStatus = "0";
            }
        }
    }

    reserveCurrentTable = async () => {
        const response = await apiServiceV2.reserveTable({
            chairs: [this.reserveTable],
            peopleCount: this.guestCount,
            reservedDate: format(this.reserveDate, "yyyy-MM-dd HH:mm")
        });
        if(!response.error) {
            globalStore.setToasts(true, 'its_success');
            sendMessageStore.sendMessage("ReactManager", "TableReserved", JSON.stringify({
                id: this.reserveTable,
                "is_reserved": `${true}`
            }));
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
        this.clearData();
    }

    unreserveCurrentTable = async () => {
        const response = await apiServiceV2.unreserveTable({
            chairs: [this.reserveTable]
        });
        if(!response.error) {
            sendMessageStore.sendMessage("ReactManager", "TableReserved", JSON.stringify({
                id: this.reserveTable,
                "is_reserved": `${false}`
            }));
            globalStore.setToasts(true, 'its_success');
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
        this.clearData();
    }

    updateTableStatus = async () => {
        if(this.tableStatus === "1") {
            await this.reserveCurrentTable()
        } else {
            await this.unreserveCurrentTable()
        }
        this.clearData();
    }

    clearData = () => {
        this.setReserveTable(0);
        this.setGuestCount(1);
        this.setReserveDate(new Date());
        this.reserveTableStatus = "";
        this.tableStatus = "Free";
    }
}

export const reserveTablePopupsStore = new ReserveTablePopupsStore();