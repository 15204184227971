import {makeAutoObservable} from "mobx";
import {TAttr, TAxis} from "../Map3d/controller.store";
import {I3Model} from "../Map3d/components/model";
import {sendMessageStore} from "../../modules/unityLayout/sendMessage.store";

interface I3ModelWithSize extends I3Model {
    size?: any
}

class RoomModelControllerStore {
    objectSetting: I3ModelWithSize;
    initialValue: I3ModelWithSize;
    currentStep: any = 0;
    endStep: any = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setCurrentStep = (step: any) => {
        this.currentStep = step;
    }

    setEndStep = (step: any) => {
        this.endStep = step;
    }

    setInitControllers = (data: any) => {
        const res = {
            position: {
                x: data.coordinate.positionX,
                y: data.coordinate.positionY,
                z: data.coordinate.positionZ
            },
            rotation: {
                x: data.coordinate.rotationX,
                y: data.coordinate.rotationY,
                z: data.coordinate.rotationZ
            },
            scale: {
                x: data.coordinate.size,
                y: data.coordinate.size,
                z: data.coordinate.size
            },
            size: data.coordinate.size,
            public_id: data.publicId,
            path: data.url
        }
        this.objectSetting = res;
        this.initialValue = res;
    }

    setControllers = (attr:TAttr, axis:TAxis, value:number) => {

        if(attr === "scale") {
            this.objectSetting = {...this.objectSetting, size: value}
            this.objectSetting = {...this.objectSetting, scale: {
                    x: value,
                    y: value,
                    z: value
                }}
        } else {
            this.objectSetting = {...this.objectSetting, [attr]:{...this.objectSetting[attr], [axis]: value}}
        }
        const data = {
            'positionX': this.objectSetting.position.x,
            'positionY': this.objectSetting.position.y,
            'positionZ': this.objectSetting.position.z,
            'rotationX': this.objectSetting.rotation.x,
            'rotationY': this.objectSetting.rotation.y,
            'rotationZ': this.objectSetting.rotation.z,
            'size': this.objectSetting.size,
        }
        const sendData = JSON.stringify(data);
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'true');
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetTransform', sendData)
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'false');
    }

    handleUndo = () => {
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'true');
        sendMessageStore.sendMessage('CommandsHistoryEventsHandler', 'Undo');
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'false');
    }

    handleRedo = () => {
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'true');
        sendMessageStore.sendMessage('CommandsHistoryEventsHandler', 'Redo');
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'false');
    }

    resetValue = (attr: TAttr, axis: TAxis)=> {
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'true');
        this.setControllers(attr, axis, this.initialValue[attr][axis])
        sendMessageStore.sendMessage('TransformEditJsEventsHandler', 'SetIsChanging', 'false');
    }
}


export const roomModelControllerStore = new RoomModelControllerStore()