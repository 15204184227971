
import { makeAutoObservable } from 'mobx';
import { apiServiceV2 } from '../../../services/apiService';
import { cacheService } from '../../../services/cacheService';
import { unityLayoutStore } from '../../unityLayout/unityLayout.store';
    

    class Scenetemplate {
        roomName:string=''
        editName:boolean=false
        videoToggle: any;
        audioToggle: any
        constructor() {
            makeAutoObservable(this);
        }

        setEditName = (name:string)=> {
            this.roomName = name;
            this.editName = true;
        }

        changeRoomName = (e:any)=> {
            this.roomName = e.target.value
        }

        saveRoomName = async()=> {
            try {
                await apiServiceV2.setRoomName(Number(cacheService.getRoomId), this.roomName);
                unityLayoutStore.setRooomData(this.roomName)
                this.roomName = ''
            } catch (error) {
                console.log(error);
            }finally {
                this.editName = false
            }
        }

        resetRoomName = () => {
            this.roomName = '';
            this.editName = false;
        }

        setVideoAudioToggle =(video:any, audio:any) =>  {
           this.videoToggle = video
           this.audioToggle = audio
        }
    
        removeState() {
            
        }
    }
    export const scenetemplateStore = new Scenetemplate();
    
    