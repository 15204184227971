import {observer} from "mobx-react-lite";
import {ticketupload} from "../ticketupload.store";
import styles from '../styles.module.scss';
import Button from "../../../ui/button";
import {useEffect, useRef, useState} from "react";
import {calculateTimeUntil} from "../../../helpers/utils";
import cn from "classnames";
import {ReactComponent as Loader} from "../../../assets/icons/loader.svg";

const TicketInfo = observer(() => {
    const interval = useRef<any>()
    const [eventDate, setEventDate] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)

    const {ticketInfoHandler, eventData} = ticketupload;

    useEffect(() => {
        interval.current = setInterval(() => {
            const currentDate: any = new Date()
            const eventStart = new Date(eventData.eventStartDate);
            setLoading(false)
            if ((+eventStart as any - +currentDate) < 1000) {
                setEventDate('')
                clearInterval(interval.current)
            } else setEventDate(calculateTimeUntil(eventStart, ` `))
        }, 1000)
        return () => clearInterval(interval.current)
    }, [])

    return (
        <div className={styles.ticketInfo}>
            {loading ? <div className={styles.ticketLoading}>
                <Loader/>
            </div> : (
                <div className={styles.congratsPopup}>
                    <div className={styles.congratsTopRow}>
                        <div className={cn(styles.modalStatus, styles.success)}>
                            <i className="icon-done"/>
                        </div>
                        <p className={styles.congratsInfo}>
                            {
                                !!eventDate ? 'Your QR is successfully uploaded!' : 'The Concert has started'
                            }
                        </p>
                    </div>
                    <div className={styles.congratsTopRow}>
                        <p className={styles.congratsMiddleText}>
                            {
                                !!eventDate ? 'The concert will start in' : 'Double-click on the concert hall to join'
                            }
                        </p>
                        {
                            !!eventDate ? (
                                <p className={styles.congratsMiddleTextBig}>
                                    <span>{eventDate}</span>
                                </p>
                            ) : <></>
                        }
                    </div>
                    <Button type="rounded-30" size='md' color='primary' onClick={() => ticketInfoHandler(eventDate)}>
                        <span className="buttonTxt">{eventDate ? "Ok" : "Join now"}</span>
                    </Button>
                </div>
            )}
        </div>
    );
})

export default TicketInfo;
