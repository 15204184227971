import {useEffect, useRef} from 'react';
import {AvaturnSDK} from '@avaturn/sdk';
import {apiServiceV2} from "../../../services/apiService";
import "../style.scss";
import {observer} from "mobx-react-lite";
import {editAvatarStore} from "../../editAvatar/editAvatar.store";

export const Avaturn = observer(({setToggleChange}: { setToggleChange: Function }) => {
    const avaturnContainerRef = useRef(null);
    const {endAvaturnSession} = editAvatarStore;

    const closePopup = () => {
        setToggleChange("");
    }

    useEffect(() => {
        const initAvaturnSDK = async () => {
            const container = avaturnContainerRef.current;
            const sdk = new AvaturnSDK();

            try {
                const {data} = await apiServiceV2.getAvaturnSession();

                await sdk.init(container, {
                    url: data,
                });
                sdk.on("export", (data: any) => {
                    endAvaturnSession(data);
                    closePopup()
                });
            } catch (error) {
                console.error('Avaturn SDK initialization failed:', error);
            }
        };

        initAvaturnSDK();
    }, []);

    return (
        <div ref={avaturnContainerRef} id="avatar-container">
        </div>

    );
});

