export const isVisitor = (list: any[], userId: any) => {
    return list.find((listUser: any) => (!!listUser?.id ? +listUser.id === +userId : +listUser.userId === +userId) && +listUser.type === 2)
}

export const checkEventsDates = (startDate: any, endDate: any) => {
    return +(new Date(endDate)) >= +(new Date()) &&
        +(new Date(startDate)) <= +(new Date())
}

export const checkEventIsEnded = (endDate: any) => {
    return +(new Date(endDate)) < +(new Date())
}

export const checkEventIsStarted = (startDate: any) => {
    return +(new Date(startDate)) > +(new Date())
}