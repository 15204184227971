import {makeAutoObservable} from 'mobx';
import {apiServiceV2} from "../../../../services/apiService";
import {sendMessageStore} from "../../../../modules/unityLayout/sendMessage.store";
import {globalStore} from "../../../../global.store";

class ResetAllChangesStore {
    showResetAllChangesPopup: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    setShowResetAllChangesPopup = (state: boolean): void => {
        this.showResetAllChangesPopup = state;
    }

    clearRoomContent = async (id: string) => {
        const res = await apiServiceV2.clearContent(id);
        if (!res.error) {
            sendMessageStore.sendMessage("ReactManager", "ClearContent");
            this.setShowResetAllChangesPopup(false);
            globalStore.setToasts(true, 'its_success');
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
    }
}

export const resetAllChangesStore = new ResetAllChangesStore();

