
import { makeAutoObservable, runInAction } from 'mobx';
import { apiServiceV2 } from '../../services/apiService';
import { globalStore } from '../../global.store';
import { queryType } from '../../types/global.types';
import {calendariumStore} from "../../modules/calendarium/calendarium.store";

class Cardsblock {
    constructor() {
        makeAutoObservable(this);
    }
    cardsInfo: any = [];
    cardsLoading: boolean = false;
    scrollLoading: boolean = false;
    scrollOffset:number = 12;
    roomsCount: number;
    cardsTotalCount: number;
    cardsQuery: queryType;
    searchRoomValue: string = "";
    page: number = 1;
    pageCount: number = 0;
    perPage: number = 0;
    openInAppData: any = null;

    setHoveredCard = (id: number) => {
        if(this.cardsInfo.length) return this.cardsInfo.filter((room: any) => room.id === id)[0];
        if(calendariumStore.events.length) return calendariumStore.events.filter((room: any) => room.id === id)[0];
    }

    setSearchRoomValue = ( val: string) => {
        this.searchRoomValue = val;
    }

    setOpenInAppData = ( data: any) => {
        this.openInAppData = data;
    }

    setRoomEventMember = (id: number) => {
        this.cardsInfo = this.cardsInfo.map((room: any) => {
            if (room.id === id) {
                const visitorIndex = room.visitors.indexOf((visitor: any) => visitor.id === globalStore.userData.id)
                if(visitorIndex !== -1) {
                    room.visitors[visitorIndex] = {
                        ...room.visitors[visitorIndex],
                        type: 2
                    }
                } else {
                    room.visitors.push({
                        username: globalStore.userData.username,
                        email: globalStore.userData.email,
                        id: globalStore.userData.id,
                        type: 2
                    });
                }
            }
            return room;
        });
        if(calendariumStore.events.length) {
            calendariumStore.events = calendariumStore.events.map((event: any) => {
                if (event.id === id) {
                    const visitorIndex = event.visitors.indexOf((visitor: any) => visitor.id === globalStore.userData.id)
                    if(visitorIndex !== -1) {
                        event.visitors[visitorIndex] = {
                            ...event.visitors[visitorIndex],
                            type: 2
                        }
                    } else {
                        event.visitors.push({
                            userId: globalStore.userData.id,
                            type: 2
                        });
                    }
                }
                if (event.id === id) {
                    event.visitors.push({
                        userId: globalStore.userData.id,
                        type: 2
                    });
                }
                return event;
            });
        }
    }

    setLoading = (status: boolean) => {
        this.cardsLoading = status
    }

    setScrollLoading = (status: boolean) => {
        this.scrollLoading = status
    }

    setPage = (pageNum: number) => {
        this.page = pageNum;
    }

    infinityLoading = async () => {
        if (this.perPage > this.pageCount) return
        this.setScrollLoading(true)
        if(this.cardsQuery) {
            this.cardsQuery.page = this.page;

            const response = await apiServiceV2.getRooms(this.cardsQuery)
            if (!response.error) {
                runInAction(() => {
                    this.cardsInfo = this.cardsInfo.concat(response.data.items)
                    this.roomsCount = this.cardsInfo.length
                    this.setPage(response.data.page + 1)
                    this.pageCount = response.data.pageCount
                    this.perPage = response.data.perPage
                })
            }
        }
        this.setScrollLoading(false)
    }

    getCards = async (query?: queryType) => {
        this.setLoading(true)
        this.setScrollLoading(true)
        const queryData = {...query, page: query?.page || this.page}
        const response = await apiServiceV2.getRooms(queryData as queryType)

        if (!response.error) {
            runInAction(() => {
                this.cardsInfo = response.data.items
                this.roomsCount = this.cardsInfo.length
                this.cardsTotalCount = response.data.totalCount
                this.cardsQuery = query as queryType
                this.setPage( response.data.page + 1)
                this.pageCount = response.data.pageCount
                this.perPage = response.data.perPage
            })
        }
        this.setLoading(false)
        this.setScrollLoading(false)

    }

    getMySpaces = async (query?: queryType) => {
        this.setLoading(true)
        this.setScrollLoading(true)
        const response = await apiServiceV2.getMySpaces(query as queryType)

        if (!response.error) {
            runInAction(() => {
                this.cardsInfo = response.data
            })
        }
        this.setLoading(false)
        this.setScrollLoading(false)
    }

    onLikeRoom = async (id: number) => {
        if (!globalStore.isLogin) return
        const res = await apiServiceV2.handleLikeRoom(id);
        if (!res.error) {
            this.cardsInfo = this.cardsInfo.map((card: any) => {
                if (card.id === id) {
                    const { isLiked } = res.data
                    card.isLiked = isLiked === 1;
                    card.likesTotalCount = card.likesTotalCount + (isLiked === 1 ? 1 : -1);
                }
                return card
            })
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
    }

    removeState = () => {
        this.cardsLoading = false;
        this.scrollLoading = false
        this.cardsTotalCount = 0;
        this.scrollOffset = 12
        this.cardsInfo = [];
        this.cardsQuery = {}
        this.setPage(1)
    }
}
export const cardsblockStore = new Cardsblock();

