import {MouseEventHandler} from 'react';
import {CustomButtonProps} from '../../types/global.types';
import './styles.scss'
import {observer} from "mobx-react-lite";
import cn from "classnames";

const Button: React.FC<Partial<CustomButtonProps>> = observer((
    {
        className = '',
        size,
        color,
        type,
        children,
        onClick,
        disabled = false,
        styles,
        ...props
    }) => {
    return (
        <button {...props} disabled={disabled} style={styles}
                className={cn(size, color, type, className, {
                    "button": true,
                    "disabled": disabled,
                })}
                onClick={onClick as MouseEventHandler<HTMLButtonElement>}>
            {children}
        </button>
    );
});

export default Button;
