import { useEffect, useRef, useState } from "react";
import "./styles.scss"
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { globalStore } from "../../global.store";
import { PdfContent } from "./pdfContent";
import { OtherContentSettings } from "../../helpers/other_content_settings";
import { ImgImporter } from "../../ui/imgImporter";


export const OtherContentModal: React.FC = () => {
    const popupRef = useRef<any>();
    const { setOtherContentModal, otherContentData, otherContentType } = globalStore
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false)
    useOnClickOutside(popupRef, () => setOtherContentModal(false));


    useEffect(() => {
        const handleEscKey = () => {
            if (!document.fullscreenElement) setFullScreenMode(false);
        }
        document.addEventListener("fullscreenchange", handleEscKey);
        document.addEventListener("webkitfullscreenchange", handleEscKey);
        document.addEventListener("mozfullscreenchange", handleEscKey);
        document.addEventListener("MSFullscreenchange", handleEscKey);
        return () => {
            document.removeEventListener("fullscreenchange", handleEscKey);
            document.removeEventListener("webkitfullscreenchange", handleEscKey);
            document.removeEventListener("mozfullscreenchange", handleEscKey);
            document.removeEventListener("MSFullscreenchange", handleEscKey);
        };
    }, []);

    const fullScreen = () => {
        if (popupRef.current.requestFullscreen) popupRef.current.requestFullscreen();
        else if (popupRef.current.webkitRequestFullscreen) popupRef.current.webkitRequestFullscreen();
        else if (popupRef.current.mozRequestFullscreen) popupRef.current.mozRequestFullscreen();
        else if (popupRef.current.msRequestFullscreen) popupRef.current.msRequestFullscreen();
        setFullScreenMode(true)
    }

    const downloadFile = async () => {
        const response = await fetch(otherContentData.file_url)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = otherContentData.title
        link.href = url;
        link.click()
    }

    return (
        <div className="modalHolder">
            <div className={`other_content_modal general-gradient-border `} ref={popupRef}>
                <div className={`modalHeader ${fullScreenMode && 'fullScreen'}`}>
                    {OtherContentSettings[otherContentType].download && <div className="download">
                        <div className="download_button" onClick={downloadFile}>
                            <ImgImporter path="download.svg" />
                        </div>
                    </div>}
                    <div className="modalHeaderContent">
                        <p className="modalHeadText">
                            {otherContentData.title.replaceAll("_", " ")}
                        </p>
                    </div>
                    <div className="modal_actions">
                        {OtherContentSettings[otherContentType].fullScreen && <div className="modalAction" onClick={() => fullScreen()}>
                            <ImgImporter path="full-screen.svg"/>
                        </div>}
                        <div className="modalAction" onClick={() => setOtherContentModal(false)}>
                            <i className="icon-close-circled" />
                        </div>
                    </div>
                </div>
                <div className="modalBody">
                    {otherContentType === 'pdf' && <PdfContent pdfUrl={otherContentData.file_url} />}
                </div>
            </div>
        </div>
    )
}