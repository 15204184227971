import { globalStore } from '../../../global.store';
import { cacheService } from '../../../services/cacheService';
import { sendMessageStore } from '../../unityLayout/sendMessage.store';
import { unityLayoutStore } from '../../unityLayout/unityLayout.store';
import { TCallData, TInviteUser, UnityLayout } from '../../unityLayout/unityLayout.type';
import { CALL_STATUS, CallStatus, STATUS } from '../hooks/constants';
import { makeAutoObservable} from 'mobx';

class PrivateTalkStore {
    privateTalkList:boolean=false;
    inTalk:boolean = false;
    invitePopup:boolean = false;
    inviteModalOpt:any={ title: 'Private Talk' }
    invited:boolean = false;
    sender:UnityLayout.RoomUsersState
    agoraToken:string;
    privateChannelName:string;
    creatorId:number;
    privateCallUser:TInviteUser;
    joinPrivateCall:Function;
    cancelPrivateCall:Function;
    callStatus:string = ''

    constructor() {
        makeAutoObservable(this);
    }
    get isCreator(){
        return cacheService.getMyId === this.creatorId
    }

    setJoinPrivateCall = (joinPrivateCall:Function)=> {
        this.joinPrivateCall = joinPrivateCall;
    }

    setCancelPrivateCall = (cancelPrivateCall:Function)=> {
        this.cancelPrivateCall = cancelPrivateCall;
    }

    changeInviteStatus = (id:number, state:CallStatus)=> {
        if(this.privateCallUser?.public_id === id && state === STATUS.None) return this.callStatus = ''

        if(id === this.privateCallUser?.public_id){
            this.callStatus = CALL_STATUS[state]
        }
    }

    privateTalkListToggle = ()=> {
        this.privateTalkList = !this.privateTalkList;
    }

    responseCallUser = async(id:number, state:CallStatus)=> {
        if(this.inTalk && state === 0 && (id === this.creatorId)) return await this.leavePrivateCall();

        this.changeInviteStatus(id, state);
        
        if(this.privateCallUser?.public_id === id && state === STATUS.Connected && !this.inTalk){
            try {

                await this.joinPrivateCall(this.privateChannelName);
                this.callStatus = '';
                this.inTalk = true;

                sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[2]);
            } catch (error) {
                console.log(error);
            }
        }
        if(state === STATUS.Connected){
            this.invitePopup = false;
        }
    }

    privateCallHandler = (data:TInviteUser) => {
        this.privateCallUser = data;
        this.callStatus = '';
        this.invitePopup = true;
    }

    callHandler = async(roomId:string) => {
        try {
            this.callStatus = 'sending';
            const channel_name = `${cacheService.getMyId}${roomId}`
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "12",
                data: {
                    "agora_channel": null,
                    "channel_name": channel_name,
                    "room_id": roomId,
                    "target_user": this.privateCallUser.public_id,
                    "sender_user": cacheService.getMyId,
                    "host_id": cacheService.getMyId
                }
            }))
            this.privateChannelName = channel_name;
            this.creatorId = cacheService.getMyId;
        } catch (error) {
            this.leaveTalk();
            console.log(error);
        }
    }

    inviteHandler = (callData:TCallData, sender:UnityLayout.RoomUsersState)=> {
        sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[1])
        this.invitePopup = true;
        this.invited = true;
        this.sender = sender;
        this.privateChannelName = `${callData.sender_user}${callData.room_id}`;
        this.agoraToken = callData.agora_channel;
        this.creatorId = callData.host_id;
    }

    sendAnswer = async (roomId:string, data:any)=> {
        sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
            command: "13",
            data: {
                "room_id": roomId,
                ...data
            }
        }))
    }

    sendUserResponse = async(id:string, answer:string)=> {
        try {
            await this.sendAnswer(id, {
                "answer": answer,
                "channel_name": this.privateChannelName
            });
            if (answer === "Accept") {
                sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[3])
                await this.joinPrivateCall(this.privateChannelName);
                sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[2])
                this.inTalk = true;
            }else{
                this.rejectPrivateCall()
            }
            this.invitePopup = false;

        } catch (e:any) {
            console.warn("ERROR: ", e.message);
            this.rejectPrivateCall()
            globalStore.setToasts(true, "microphone_permission");
        }
    }

    rejectPrivateCall = ()=> {
        sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[4])
        setTimeout(() => {
            sendMessageStore.sendMessage("ReactManager", "ChangePrivateCallState", CALL_STATUS[0])
            this.invitePopup = false;
        }, 1000);
    }

    sendInviteFromList = (user: TInviteUser)=> {
        const inCall = unityLayoutStore.userInCall(user.public_id);
        if(inCall)return null;
        const roomId = cacheService?.getRoomId;

        this.privateCallUser = user;
        this.callHandler(roomId);
    }

    leavePrivateCall = async ()=> {
        const roomId = cacheService?.getRoomId;
        try {
            await this.cancelPrivateCall(roomId);
            this.leaveTalk();
        } catch (error) {
            console.log(error);
        }
    }    

    leaveUserHandler = (roomId:string, userId:number)=> {
        sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
            command: "14",
            data: {
                public_id: `${userId}`
            }
        }))
    }

    leaveTalk = ()=> {
        this.inTalk = false;
        this.privateTalkList = false;
    }

    closePopup = ()=> {
        this.invitePopup = false;
    }
}
export const privateTalkStore = new PrivateTalkStore();
    