import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import "./styles.scss"
export const PdfContent: React.FC<{pdfUrl:string}> = ({ pdfUrl }) => {
    return (
        <>
            <div className="pdf_wrapper">
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={pdfUrl} />
                </Worker>
            </div>
        </>
    )
}