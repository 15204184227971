import { authSectionTypes } from "../modules/authorization/authorization.type";

export const AuthForm: authSectionTypes = {
    login: {
        1: {
            step: 1,
            title: "Login with email",
            subtitle: {
                text: "Don’t have an account yet?",
                navigation: {
                    text: "SignUp",
                    url: "registration"
                }
            },
            button: {
                func: "logIn",
                type: 1,
                text: "Log In",
                disabled: true
            },
            inputs: [
                {
                    type: "email",
                    typeOfInput: "input",
                    label: "Email",
                    name: "email",
                    placeholder: "Enter your email",
                    validation: {
                        type: "text",
                        status: null,
                    },
                    disabled: false,
                    id: 1
                },
                {
                    type: "password",
                    label: "Password",
                    typeOfInput: "input",
                    name: "password",
                    placeholder: "Enter your password",
                    validation: {
                        type: "text",
                        status: null,

                    },
                    disabled: false,
                    id: 2
                },
            ],
            loginWith: [
            ],
            error: {
                status: false,
                message: ''
            },
            rememberMeBlock: true,
            termsandPrivecy: false,
            backButton: {
                status: false,
                step: 0
            }

        },
        2: {
            step: 2,
            title: "Verify Email",
            subtitle: {
                text: "We sent a code to",
                navigation: {
                    text: "",
                    url: ""
                }
            },
            button: {
                func: "verifyEmail",
                type: 1,
                text: "Confirm",
                disabled: true
            },
            inputs: [
                {
                    type: "number",
                    label: "Code",
                    typeOfInput: "input",
                    name: "code",
                    placeholder: "Enter",
                    validation: {
                        type: "text",
                        status: null
                    },
                    disabled: false,
                    id: 9
                },
            ],
            error: {
                status: false,
                message: ''
            },
            resend: {
                status: true,
                func: 'resendVerifyCode'
            },
            backButton: {
                status: true,
                step: 1
            }
        }
    },
    registration: {
        1: {
            step: 1,
            title: "Register",
            subtitle: {
                text: "Already have an account?",
                navigation: {
                    text: "LogIn",
                    url: "login"
                }
            },
            button: {
                func: "registration",
                type: 1,
                text: "Continue",
                disabled: true
            },
            inputs: [
                {
                    type: "email",
                    typeOfInput: "input",
                    label: "Email",
                    name: "email",
                    placeholder: "Enter your email",
                    validation: {
                        type: "email",
                        status: null,
                        error: 'incorrect email, please try again'

                    },
                    disabled: false,
                    id: 3
                },
                {
                    type: "text",
                    label: "Username",
                    typeOfInput: "input",
                    name: "name",
                    placeholder: "Enter your username",
                    validation: {
                        type: "text",
                        status: null,

                    },
                    disabled: false,
                    id: 4
                },
                {
                    type: "select",
                    typeOfInput: "select",
                    label: "Gender",
                    name: "gender",
                    placeholder: "Select your gender",
                    validation: {
                        type: "select",
                        status: null
                    },
                    options: [
                        {
                            value: "1",
                            id: 1,
                            text: "Male"

                        },
                        {
                            value: "2",
                            id: 2,
                            text: "Female"

                        },
                        // {
                        //     value: "other",
                        //     id: 3,
                        //     text: "Other"

                        // },
                    ],
                    disabled: false,
                    id: 5
                },
                {
                    type: "date",
                    label: "Date of birth",
                    typeOfInput: "date",
                    name: "date",
                    placeholder: "Enter your Date",
                    validation: {
                        type: "date",
                        status: null,
                        error: 'Date of birth cannot be less than 4 years'
                    },
                    disabled: false,
                    id: 6
                },
                {
                    type: "new-password",
                    label: "Create Password",
                    name: "password",
                    typeOfInput: "input",
                    placeholder: "Create password",
                    validation: {
                        type: "password",
                        status: null
                    },
                    disabled: false,
                    id: 7
                },
                {
                    type: "confirm-password",
                    label: "Confirm Password",
                    typeOfInput: "input",
                    name: "confpassword",
                    placeholder: "Confirm password",
                    validation: {
                        type: "confirmPass",
                        status: null,
                        error: 'Make sure both passwords are identical'
                    },
                    disabled: false,
                    id: 8
                },
            ],
            error: {
                status: false,
                message: ''
            },
            termsandPrivecy: true,
            backButton: {
                status: false,
                step: 0
            }
        },
        2: {
            step: 2,
            title: "Verify Email",
            subtitle: {
                text: "We sent a code to",
                navigation: {
                    text: "",
                    url: ""
                }
            },
            button: {
                func: "verifyEmail",
                type: 1,
                text: "Confirm",
                disabled: true
            },
            inputs: [
                {
                    type: "number",
                    label: "Code",
                    typeOfInput: "input",
                    name: "code",
                    placeholder: "Enter",
                    validation: {
                        type: "text",
                        status: null
                    },
                    disabled: false,
                    id: 9
                },
            ],
            error: {
                status: false,
                message: ''
            },
            resend: {
                status: true,
                func: 'resendVerifyCode'
            },
            backButton: {
                status: true,
                step: 1
            }
        }
    },
    forgot: {
        1: {
            step: 1,
            title: "Recover password",
            subtitle: {
                text: "Please, enter your email. We will sent a verification email.",
            },
            button: {
                func: "checkForgotEmail",
                type: 1,
                text: "Continue",
                disabled: true
            },
            inputs: [
                {
                    type: "email",
                    typeOfInput: "input",
                    label: "Email",
                    name: "email",
                    placeholder: "Enter your email",
                    validation: {
                        type: "email",
                        status: null,
                        error: 'Incorrect email, please try again'
                    },
                    disabled: false,
                    id: 109
                },
            ],
            error: {
                status: false,
                message: ''
            },
            rememberMeBlock: false,
            backButton: {
                status: true,
                step: 0
            }

        },
        2: {
            step: 2,
            title: "Recover password",
            subtitle: {
                text: "We sent a code to",
                navigation: {
                    text: "",
                    url: ""
                }
            },
            button: {
                func: "checkForgotCode",
                type: 1,
                text: "Continue",
                disabled: true
            },
            inputs: [
                {
                    type: "text",
                    label: "Code",
                    typeOfInput: "input",
                    name: "code",
                    placeholder: "Enter",
                    validation: {
                        type: "number",
                        status: null,
                    },
                    disabled: false,
                    id: 9
                },
            ],
            error: {
                status: false,
                message: ''
            },
            rememberMeBlock: false,
            resend: {
                status: true,
                func: 'resendForgotCode'
            },
            backButton: {
                status: true,
                step: 1
            }


        },
        3: {
            step: 3,
            title: "Recover password",
            button: {
                func: "createNewPass",
                type: 1,
                text: "Confirm",
                disabled: true
            },
            inputs: [
                {
                    type: "password",
                    label: "Create Password",
                    name: "password",
                    typeOfInput: "input",
                    placeholder: "Create password",
                    validation: {
                        type: "password",
                        status: null
                    },
                    disabled: false,
                    id: 7
                },
                {
                    type: "password",
                    label: "Confirm Password",
                    typeOfInput: "input",
                    name: "confpassword",
                    placeholder: "Confirm password",
                    validation: {
                        type: "confirmPass",
                        status: null,
                        error: 'Make sure both passwords are identical'
                    },
                    disabled: false,
                    id: 8
                },
            ],
            error: {
                status: false,
                message: ''
            },
            rememberMeBlock: false,
            backButton: {
                status: true,
                step: 2
            }
        }
    },
}