export class CacheService {
    get(item: string): string | null {
        return localStorage.getItem(item) || null
    }
    set(item: string, value: string = ""): void {
        localStorage.setItem(item, value)
    }

    get getMyId(){
        return Number(localStorage.getItem('Id'));
    }

    get getRoomId():string{
        return localStorage?.getItem('roomId') as string;
    }

    get isGuest() {
        return localStorage.getItem('UserType') === 'guest'
    }

    clear(): void {
        const lng: string = localStorage.getItem("lng") || "";
        localStorage.clear()
        localStorage.setItem("lng", lng)
    }


    getAuthToken() {
        try {
            return localStorage.getItem('Authorization')?.split('Token')[1]?.trim()
        } catch (err) {
            console.log('Local Storage err == ', err);
        }
    }

    remove(item: string): void {
        localStorage.removeItem(item)
    }
}


export const cacheService = new CacheService()