import { useEffect, useState } from "react"
import "./styles.scss"

export const ImgImporter: React.FC<{ path: string, alt?: string }> = ({ path, alt = 'img' }) => {

     const [image, setImage] = useState<string>("")
     useEffect(() => {
          (async () => {
               try {
                    const response = await import(`../../assets/images/${path}`)
                    setImage(response?.default)
               } catch (err) {
                    setImage('')
               }
          })()
     }, [path])
     return image ? <img className="image_block" src={image} alt={alt} /> : <></>
}