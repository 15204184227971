
import { makeAutoObservable, runInAction } from 'mobx';
import { apiServiceV2 } from '../../services/apiService';
import {globalStore} from "../../global.store";


class Calendarium {
    eventsCountByDay: any = {}
    currentDay: string = ""
    events: any = []
    constructor() {
        makeAutoObservable(this);
    }

    getEventsByDate = async (day: string) => {
        const response = await apiServiceV2.getEvents({ eventDay: day })
        if (!response.error) runInAction(() => this.events = response.data.items)
    }

    getEventsCountByDay = async () => {
        const res = await apiServiceV2.getEventsCount()
        if (!res.error) runInAction(() => this.eventsCountByDay = res.data.items)
    }

    getUpcomingEvents = async () => {
        const response = await apiServiceV2.getEvents()
        if (!response.error) runInAction(() => this.events = response.data.items.map((item:any)=> ({...item, name:item.events[0].eventName})))
    }

    setCurrentDay = (date: string) => {
        this.currentDay = date;
    }

    onLikeRoom = async (roomId: string | undefined) => {
        const res = await apiServiceV2.handleLikeRoom(Number(roomId));
        if (!res.error) {
            this.events = this.events.map((event: any) => {
                if(event.id === roomId) {
                    return {
                        ...event,
                        isLiked: res.data.isLiked === 1,
                    }
                }
                return event
            });
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
    };

    removeState = () => {
        this.eventsCountByDay = {}
        this.events = []
    }
}
export const calendariumStore = new Calendarium();

