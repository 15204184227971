
import {useEffect, useRef} from 'react'
import { ModalContainerViewProps } from '../modalcontainer.type'
import './modalcontainer.scss'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import {observer} from "mobx-react-lite";
import cn from "classnames";


export const ModalcontainerView: React.FC<ModalContainerViewProps> = observer((
    {
        className='',
        backBtn,
        children,
        closePopup = () => {},
        headerText,
        backBtnInfo = {
            action: () => {
            },
            visible: true
        },
        minimized = false,
        canClose = true,
    }): JSX.Element => {
    const popupRef = useRef<any>()
    useOnClickOutside(popupRef, () => closePopup(false));

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, []);

    return (
        <div className={`modalContainerHolder ${className}`}>
            <div className={`modalContainer general-gradient-border ${minimized ? "sizeSmall" : ""}`} ref={popupRef}>
                <div className={`modalContainerHeader ${!headerText && 'without_shadow'} ${!headerText?.subTitle && "without_subtitle"}`}>
                    {backBtn && <div className="modalAction">
                        {backBtnInfo?.visible && <i className="icon-arrow-left" onClick={() => backBtnInfo.action()}/>}
                    </div>}
                    <div className="modalContainerHeaderContent">
                        <p className="modalContainerHeadText">
                            {headerText?.title}
                        </p>
                        <p className="modalContainerHeadSubTitle">
                            {headerText?.subTitle}
                        </p>
                    </div>
                    {canClose && <div className="modalContainerAction" onClick={() => closePopup(false)}>
                        <i className="icon-close-circled"/>
                    </div>}
                </div>
                <div className={cn("modalContainerBody", {
                    "warningContainer": !headerText
                })}>
                    {children}
                </div>
            </div>
        </div>
    )
})