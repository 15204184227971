import { makeAutoObservable } from 'mobx';
import { authorizationStore } from '../../modules/authorization/authorization.store';
import { sectionTypes } from '../../modules/authorization/authorization.type';
import { apiServiceV2 } from "../../services/apiService";
import { DashboardBannersType, DashboardVideosType, PartnersType } from "./dashboard.type";


class Dashboard {
    authPopupActive: boolean = false;
    authPopupType: string = "";
    dashboardBanners = [] as DashboardBannersType[];
    partners = [] as PartnersType[];
    dashboardVideos = [] as DashboardVideosType[];
    setSectionContent = authorizationStore.setSectionContent

    constructor() {
        makeAutoObservable(this);
    }

    setDashboardBanners = (data: any) => {
        this.dashboardBanners = data;
    }

    setDashboardVideos = (data: any) => {
        this.dashboardVideos = data;
    }

    setPartners = (data: any) => {
        this.partners = data;
    }

    setAuthPopupActive = (status: boolean) => {
        this.authPopupActive = status
    }

    setAuthPopupType = (type: sectionTypes) => {
        this.authPopupType = type;
        this.setSectionContent(type)
    }

    getDashboardBanners = async () => {
        try {
            
        } catch (error) {

        }
    }

    getPartners = async () => {
        try {
            const res = await apiServiceV2.getDashboardPartners();
            this.setPartners(res.data.items);

        } catch (error) {
            this.partners = [];
        }
    }

    getDashboardVideos = async () => {
        try {
            const res = await apiServiceV2.getDashboardBaner();
            this.setDashboardVideos([res.data]);
        } catch (error) {
            console.log(error);
        }
    }


    removeState = () => {

    }


}
export const dashboardStore: Dashboard = new Dashboard();

