
import { makeAutoObservable } from 'mobx';


class PopokGame {
    gameUrl:string
    openGame:boolean=false
    constructor() {
        makeAutoObservable(this);
    }

    setOpenGame = (bool:boolean) => {
        this.openGame = bool;
    }

    setGameUrl = (url:string) => {
        this.gameUrl = url;
        this.openGame = true
    }

}
export const popokGameStore = new PopokGame();

