import { observer } from "mobx-react-lite";
import { ticketupload } from "./ticketupload.store";
import styles from './styles.module.scss';
import cn from "classnames";
import UploadBody from "./UploadBody";
import TicketInfo from "./TicketInfo";
import {globalStore} from "../../global.store";
import {useEffect, useRef} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {useLocation, useNavigate} from "react-router-dom";
import {isVisitor} from "../../helpers/isVisitor";

const TicketUpload = observer(() => {
    const location = useLocation();
    const navigate = useNavigate()
    const {
        uploadTicketPopup,
        eventData,
        visitors,
        roomId
    } = ticketupload;

    const {
        userData
    } = globalStore;

    const ref = useRef<any>(null);
    const closePopup = () => {
        uploadTicketPopup(false)
        if(location.pathname === `/room/${roomId}`) {
            navigate(`/`);
        }
    }

    useOnClickOutside(ref, closePopup)

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, []);

    return (
        <div className={styles.modalHolder}>
            <div ref={ref} className={cn({[styles.modal]: true, [styles.general_gradient_border]: true}) }>
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeaderContent}>
                        <p className={styles.modalHeadText}>
                            {!isVisitor(visitors, userData.id) ? 'Upload Your Ticket Number' : +(new Date(eventData.eventStartDate)) > +Date.now() ? "Congrats" : "Success"}
                        </p>
                    </div>
                    <div className={styles.modalAction} onClick={closePopup}>
                        <i className='icon-close'/>
                    </div>
                </div>
                <div className={styles.modalBody}>
                    {
                        !isVisitor(visitors, userData.id) ? (
                            <UploadBody/>
                        ) : (
                            <TicketInfo/>
                        )
                    }
                </div>
            </div>
        </div>
    );
})

export default TicketUpload;
