import { NotificatonProps } from "../components/modalcontainer/modalcontainer.type";

export const NotificationsContent: { [key: string]: NotificatonProps } = {
    frozen: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Restricted!", subTitle: "Your mic and camera are off by the space admin." },
        action: 'closePopup'
    },
    unfrozen: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Here We Go!", subTitle: "You will be able to communicate" },
        action: 'closePopup'
    },
    admin: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Congrats! You’re an Admin!", subTitle: "Now you have access to multiple admin features." },
        action: 'closePopup'
    },
    remove_admin: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "You’ve just been removed from the admin’s list.", subTitle: "" },
        action: 'closePopup'
    },
    kicked: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Kicked off", subTitle: "You have been kicked for" },
        action: 'closePopup'
    },
    block: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Oops, Blocked!", subTitle: "You’ve just been blocked, sorry." },
        action: 'closePopup'
    },
    start_block: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Oops, Blocked!", subTitle: "The space access is restricted by the owner." },
        action: 'navigateToMain'
    },
    start_kicked: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Kicked off", subTitle: "You have been kicked for" },
        action: 'navigateToMain'
    },
    room_error: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Room does not exist", subTitle: "" },
        action: 'navigateToMain'
    },
    duble_login: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Double login", subTitle: "You can use one account to log in from one device only at a time." },
        action: 'closePopup'
    },
    login_error: {
        button: { color: 'secondaryLight', text: 'OK' },
        headerText: { title: "Sign In to continue!", subTitle: "" },
        action: 'closePopup'
    },
}