import {observer} from "mobx-react-lite";
import "../policies.scss";
import MetaTags from "../../MetaTags";

const TermsAndConditions = observer(() => {
    return <div className="policyInnerContainer container">
        <MetaTags
            title={"YoCerebrum | Terms and Conditions"}
            description={"YoCerebrum, a metaverse world to create and connect. Buy, sell and rent spaces, build corporations and digital twins, attend events and shop in virtual reality"}
        />
        <h1>YOCEREBRUM TERMS AND CONDITIONS (Platform, Software, Website)</h1>
        <div className="policyContent">
            <p>
                These Terms govern
            </p>
            <ul>
                <li>the use of this Website, and,</li>
                <li>any other related Agreement or legal relationship with the Owner</li>
            </ul>
            <p>
                in a legally binding way. Capitalized words are defined in the relevant dedicated section of this
                document.
            </p>
            <p>
                The User must read this document carefully.
            </p>
            <p className={"padding"}>
                This Website is provided by:
            </p>
            <p>
                <strong>Imagine Hub Project Management L.L.C</strong>
            </p>
            <p className={"padding"}>
                Building 55, D3 street1 Floor, Office 107B
            </p>
            <p className={"padding"}>
                <strong>Building 55, D3 street1 Floor, Office 107B </strong><a
                href="mailto:info@yocerebrum.com">info@yocerebrum.com</a>
            </p>
            <p className={"padding"}>
                <strong>This Website: </strong>The property that enables the provision of the Service;</p>
            <p className={"padding"}>
                <strong>Agreement: </strong>Any legally binding or contractual relationship between the Owner and the
                User, governed by these Terms;
            </p>
            <p className={"padding"}>
                <strong>Cerebrum: </strong>Refers to a specific project of a virtual space within Yocerebrum realm
                operated by the Website. It encompassed all associated digital assets, content, features and
                functionalities provided by the Website.
            </p>
            <p className={"padding"}>
                <strong>Cadastral Value: </strong>as defined within these Terms, specifically pertains to buyback
                transactions involving NFTs. This valuation mechanism is applicable solely in the context of repurchase
                of NFT by Website Owner. The Cadastral value serves as the basis for determining the repurchase price of
                the NFT by the Website Owner, as outlined in the terms and conditions governing the buyback process."
            </p>
            <p className={"padding"}>
                <strong>Digital space: </strong>Refers to the virtual environment or online platform provided by the
                Owner within the Yocerebrum realm for the users to interact, share, or engage in various digital
                activities;
            </p>
            <p className={"padding"}>
                <strong>Explicit content: </strong>refers to material that contains explicit, graphic, or detailed
                depictions of subject matter, such as nudity, sexual acts, violence, or profanity. This type of content
                is intended for mature audiences and may not be suitable for individuals below a certain age or those
                who may find such material offensive or inappropriate.
            </p>
            <p className={"padding"}>
                <strong>Yocerebrum: </strong>represents a comprehensive virtual space formed by the convergence of
                physical and digital realities. It includes augmented and virtual reality, offering users immersive
                digital experiences and real-time interaction with others. The Yocerebrum comprises interconnected
                digital spaces, simulations, and experiences accessible across different platforms and technologies.
            </p>
            <p className={"padding"}>
                <strong>Lolik: </strong>refers to Lolik Ltd, a company providing staking services as described in these
                Terms, The services offered by Lolik may include but are not limited to staking, validating, and
                securing cryptocurrencies or digital assets on behalf of Users.
            </p>
            <p className={"padding"}>
                <strong>NFT: </strong>In the context of this terms a Non-Fungible Token (NFT) is a type of digital asset
                that represents ownership of the Digital space using blockchain technology. NFTs can be bought, sold,
                and traded on online platforms and marketplaces acceptable/applicable on the Website.
            </p>
            <p className={"padding"}>
                <strong>Owner (or We, Us): </strong>Indicates the natural person(s) or legal entity that provides this
                Website and/or the Service to Users.
            </p>
            <p className={"padding"}>
                <strong>Physical space: </strong>pertains to tangible, real world locations or areas that exist outside
                the digital realm, encompassing the physical environment in which individuals reside or conduct physical
                activities.
            </p>
            <p className={"padding"}>
                <strong>Service: </strong>The service provided by this Website as described in these Terms and on this
                Website.
            </p>
            <p className={"padding"}>
                <strong>Terms: </strong>All provisions applicable to the use of this Website and/or the Service as
                described in this document, including any other related documents or agreements, and as updated from
                time to time.
            </p>
            <p className={"padding"}>
                <strong>User (or You): </strong>Indicates any natural person or legal entity using
                this Website.
            </p>
            <p>
                <strong>Information about this Website</strong>
            </p>
            <p className={"hyper_padding"}>
                Every individual or business can get their own Digital space within Cerebrum
                in the scope of Yocerebrum realm in just a few clicks by purchase of NFTs from the Website. With each
                Digital space You will get a diversity of tools and integrations to showcase the products/services, host
                events and show off your aspirations.
            </p>
            <p>
                <strong>TERMS OF USE</strong>
            </p>
            <p>
                Unless otherwise specified, the terms of use detailed in this section apply generally when using this
                Website.
            </p>
            <p>
                Single or additional conditions of use or access may apply in specific scenarios and in such cases are
                additionally indicated within this document.
            </p>
            <p className={"padding"}>By using this Website, Users confirm to meet the following requirements:</p>
            <p className={"padding"}><strong>Purchasing and Selling Assets in Yocerebrum</strong></p>
            <p>
                <strong>TRANSACTION TERMS:</strong>
            </p>
            <p>
                <strong>Purchase terms:</strong>
            </p>
            <p className={"padding"}>
                The User acknowledges and agrees that the purchase of NFTs from the Website
                constitutes a transaction
                wherein the User obtains proof of ownership of Digital space within Cerebrum. In consideration for the
                NFT purchased, the User agrees to provide payment in the form of Fasttoken (FTN) or any other
                cryptocurrency accepted on the Website. Additionally, the User acknowledges that the FTN amount provided
                for the purchase of NFTs may be staked by Lolik. The User further acknowledges and agrees that the
                staking of FTN may be subject to risks and fluctuations in value, and neither the Website, nor the Lolik
                shall not be held liable for any losses incurred as a result of such staking activities.</p>
            <p>
                <strong>Finality and Non-Refundability:</strong>
            </p>
            <p className={"padding"}>
                User expressly understands and agrees that transactions involving the acquisition
                of Digital spaces within the Cerebrum are deemed final and non-refundable. Once a transaction is
                completed, User has no entitlement to a refund or reversal, and User bears the responsibility for
                ensuring the accuracy of the transaction details. NFT bought for the Digital space grants Users rights
                for sale only in the ftNFT.com platform.</p>
            <p>
                <strong>User Accountability:</strong>
            </p>
            <p className={"padding"}>
                User is solely accountable for all actions undertaken during the purchase process.
                Any errors, discrepancies, or inaccuracies in the transaction details, including but not limited to the
                chosen Digital space, quantity, or payment information, are the User's responsibility.</p>
            <p>
                <strong>Transaction Verification:</strong>
            </p>
            <p className={"padding"}>
                User is encouraged to thoroughly review and verify transaction details before
                confirming the purchase. The Website may provide mechanisms for users to review and confirm their
                selections to minimize the likelihood of errors.</p>
            <p>
                <strong>Transaction Security:</strong>
            </p>
            <p className={"padding"}>
                The Website implements security measures to safeguard transaction processes.
                However, You are also responsible for ensuring the security of Your account credentials and taking
                appropriate measures to prevent unauthorized access or transactions.</p>
            <p>
                <strong>Transaction Verification:</strong>
            </p>
            <p className={"padding"}>
                User is encouraged to thoroughly review and verify transaction details before
                confirming the purchase. The Website may provide mechanisms for users to review and confirm their
                selections to minimize the likelihood of errors.</p>
            <p>
                <strong>Transaction Security:</strong>
            </p>
            <p className={"padding"}>
                User is encouraged to thoroughly review and verify transaction details before
                confirming the purchase. The Website may provide mechanisms for users to review and confirm their
                selections to minimize the likelihood of errors.</p>
            <p>
                <strong>Currency and Payment:</strong>
            </p>
            <p className={"padding"}>
                Transactions may involve the use of virtual currencies or other payment methods
                specified on the Website. You are obligated to comply with the designated payment procedures and ensure
                the availability of sufficient funds or credits before initiating a transaction.</p>
            <p>
                <strong>Transaction Records:</strong>
            </p>
            <p className={"padding"}>
                You should maintain transaction records on your own, through software
                cryptocurrency wallets determined on the Website. It is Your responsibility to keep track of your
                transaction history for reference and verification purposes.</p>
            <p>
                <strong>Dispute Resolution for Transactions:</strong>
            </p>
            <p className={"padding"}>
                In the event of transaction-related disputes, You are encouraged to follow the dispute resolution
                mechanisms outlined in these Terms. This may involve contacting customer support, submitting relevant
                evidence, or engaging in arbitration processes as stipulated by these Terms.
            </p>
            <p>
                <strong>Changes to Transaction Terms:</strong>
            </p>
            <p className={"padding"}>
                We reserve the right to update or modify transaction terms. Users will be notified of any changes, and
                continued use of the platform implies acceptance of the updated terms. Regularly reviewing transaction
                terms is recommended to stay informed about any modifications that may impact user interactions within
                the Yocerebrum.
            </p>
            <p>
                <strong>Buyback Clause:</strong>
            </p>
            <p>
                In the event of a buyback transaction by Us, the NFT repurchase price shall be determined based on the
                following formula.
            </p>
            <i>
                Calculation of Cadastral Value of Cerebrum
            </i>
            <p>
                C1 = CFTN x CCA
            </p>
            <p>
                C1 - Cadastral factor
            </p>
            <p>
                CFTN –The coefficient of change of the market value of the USDT/FTN pair
            </p>
            <p>
                CCA - The demand factor for a given zone in Cerebrum
            </p>
            <p>
                CV = Csize X C1
            </p>
            <p>
                CV - cadastral value of the area
            </p>
            <p className={"hyper_padding"}>
                Csize - Area size factor
            </p>
            <p>
                <strong>REAL-TIME PHYSICAL SPACE OWNERS' RIGHT OF PREFERENCE:</strong>
            </p>
            <p>
                <strong>Preferential Transaction Opportunities:</strong>
            </p>
            <p className={"padding"}>
                In the event of a Digital space transaction, real-time physical space owners may be given priority
                access to available spaces before they are made accessible to other users within the timeframe specified
                on the Website. This information will be disclosed on the Website.
            </p>
            <p>
                <strong>Verification and Authentication:</strong>
            </p>
            <p className={"padding"}>
                In case of priority access of real-time physical space owners, to exercise the right of preference,
                real-time Physical space owners must undergo a verification and authentication process, confirming their
                status as legitimate owners of corresponding Physical spaces. The platform may request documentation or
                other evidence to substantiate ownership claims.
            </p>
            <p>
                <strong>Time-Limited Preference Window:</strong>
            </p>
            <p className={"padding"}>
                In case of priority access of real-time physical space owners, the right of preference is time-limited
                and applicable within a specified window of opportunity published on the Website. Real-time Physical
                space owners must express their interest and complete the transaction within this designated timeframe
                to avail themselves of the priority status.
            </p>
            <p>
                <strong>Equitable Allocation:</strong>
            </p>
            <p className={"padding"}>
                In case of priority access of real-time physical space owners, if a Real-time Physical space owner opts
                not to exercise their preference within the stipulated timeframe, the available Digital spaces may be
                made accessible to other users.
            </p>
            <p>
                <strong>Platform's Right to Modify:</strong>
            </p>
            <p className={"hyper_padding"}>
                We reserve the right to modify these Terms related to the right of preference, including the duration of
                the preference window, verification procedures, and any other relevant aspects. Users will be notified
                of such modifications.
            </p>
            <p>
                <strong>OWNERSHIP DISCLAIMERS:</strong>
            </p>
            <p>
                <strong>Virtual Nature of Ownership:</strong>
            </p>
            <p className={"padding"}>
                You explicitly acknowledge and agree that the ownership acquired through the purchase of Digital spaces
                within Cerebrum is strictly virtual and confined to the parameters of the Yocerebrum realm. It does not
                confer any tangible or legal ownership rights in the physical world.
            </p>
            <p>
                <strong>No Real-World Property Rights:</strong>
            </p>
            <p className={"padding"}>
                The ownership of Digital spaces does not grant users any rights to physical assets, real estate, or any
                tangible property beyond the virtual representation within the Yocerebrum. Users are expressly
                prohibited from interpreting their digital ownership as having implications in the physical world.
            </p>
            <p>
                <strong>Platform's Control Over Virtual Assets:</strong>
            </p>
            <p className={"padding"}>
                The Website retains full control and ownership of the underlying technology, infrastructure, and
                framework supporting Digital spaces. Users have a limited, non-transferable right to access and use
                these Digital spaces, subject to compliance with the platform's policies.
            </p>
            <p>
                <strong>Non-Assignable Rights:</strong>
            </p>
            <p className={"padding"}>
                Users cannot assign, sublicense, or transfer their virtual ownership rights to others without explicit
                authorization from the platform. Any attempt to do so may result in the nullification of ownership
                rights and potential account suspension. Users can transfer their rights to another user through selling
                the NFT in ftnft.com.
            </p>
            <p>
                <strong>Platform's Right to Modify:</strong>
            </p>
            <p className={"padding"}>
                The Yocerebrum platform reserves the right to modify, alter, or discontinue Digital spaces, features, or
                functionalities, as well as the overall Yocerebrum environment. Such modifications do not impact the
                fundamental nature of users' virtual ownership but may affect the user experience within the platform.
            </p>
            <p>
                <strong>Termination of Access:</strong>
            </p>
            <p className={"padding"}>
                In the event of violations of these Terms and platform policies, the Owner reserves the right to suspend
                or terminate User access to the acquired Digital spaces. This termination does not imply any
                compensation or transfer of ownership rights to the user.
            </p>
            <p>
                <strong>Disclaimer of Real-World Transactions:</strong>
            </p>
            <p className={"padding"}>
                Users are cautioned against interpreting digital ownership as having any financial, legal, or real-world
                value. The Yocerebrum platform does not endorse or recognize any real-world transactions based on the
                virtual ownership of digital spaces within its environment.
            </p>
            <p>
                <strong>Acknowledgment of Limited Rights:</strong>
            </p>
            <p className={"hyper_padding"}>
                By participating in the Yocerebrum and acquiring Digital spaces, You acknowledge that your rights are
                limited to the virtual realm established by the platform and do not extend beyond the defined boundaries
                of the Yocerebrum.
            </p>
            <p>
                <strong>USER ACCOUNTS</strong>
            </p>
            <p>
                <strong>Account Creation:</strong>
            </p>
            <p className={"padding"}>
                Users are cautioned against interpreting digital ownership as having any financial, legal, or
                real-world value. The Yocerebrum platform does not endorse or recognize any real-world transactions
                based on the virtual ownership of digital spaces within its environment.
            </p>
            <p>
                <strong>Account Security:</strong>
            </p>
            <p className={"padding"}>
                You are urged to prioritize account security. This involves choosing a strong, unique password,
                refraining from sharing login credentials, and promptly reporting any unauthorized access or suspicious
                activities to the Yocerebrum platform's support team.
            </p>
            <p>
                <strong>User Responsibilities:</strong>
            </p>
            <p className={"padding"}>
                You commit to using your account responsibly and ethically. This includes adherence to content
                guidelines, respect for intellectual property rights, and compliance with applicable laws and
                regulations. Any misuse of the account may result in disciplinary actions.
            </p>
            <p>
                <strong>Age Restrictions:</strong>
            </p>
            <p className={"padding"}>
                Account creation can be limited to individuals who meet the minimum age requirements specified by the
                platform. Users under the required age must obtain verifiable consent from a parent or legal guardian
                before creating an account.
            </p>
            <p>
                <strong>Account Termination:</strong>
            </p>
            <p className={"padding"}>
                We reserve the right to terminate or suspend User account for various reasons, such as fraudulent
                activities, security breaches, or violations of the platform's policies. User will be notified of the
                reasons for such actions whenever possible.
            </p>
            <p>
                <strong>User Data and Privacy:</strong>
            </p>
            <p className={"padding"}>
                We are committed to protecting your privacy and the confidentiality of your personal information. By
                using our Website, you consent to the collection, use, and disclosure of your personal data as outlined
                in our Privacy Policy. We may collect certain information about you in order to provide you with our
                services and improve your user experience.
            </p>
            <p>
                <strong>Account Deactivation and Deletion:</strong>
            </p>
            <p className={"padding"}>
                You can deactivate your account temporarily or request permanent deletion, subject to the Website
                procedures. Deactivation temporarily suspends account access, while deletion results in the permanent
                removal of the account and associated data.
            </p>
            <p>
                <strong>Account Recovery:</strong>
            </p>
            <p className={"padding"}>
                In cases of forgotten passwords or account access issues, users can follow the platform's established
                account recovery procedures. This may involve email verification, security questions, or other identity
                verification methods to ensure the security of the account.
            </p>
            <p>
                <strong>Changes to Account Terms:</strong>
            </p>
            <p className={"hyper_padding"}>
                We reserve the right to update or modify account-related terms. Users will be notified of such changes,
                and continued use of the account implies acceptance of the updated terms. Regularly reviewing account
                terms is advisable to stay informed about any modifications that may impact the user experience.
            </p>
            <p>
                <strong>INTELLECTUAL PROPERTY RIGHTS:</strong>
            </p>
            <p className={"hyper_padding"}>
                All digital spaces in the Yocerebrum are subject to intellectual property rights held by the platform.
                Users agree not to infringe upon these rights, including reproduction, distribution, or creation of
                derivative works without explicit authorization from Owner.
            </p>
            <p>
                <strong>USAGE RESTRICTIONS:</strong>
            </p>
            <p className={"padding"}>
                Users agree not to engage in activities that violate platform policies, including but not limited to
                illegal or unethical conduct, harassment, or the creation of Explicit content within their acquired
                Digital spaces. Failure to adhere to these user restrictions may result in various consequences,
                including warnings, suspension, or termination of user accounts and legal actions as deemed necessary.
                We retain the authority to delete any user account, content that is deemed explicit, offensive in our
                sole discretion.
            </p>
            <p>
                <strong>Platform Changes and Updates:</strong>
            </p>
            <p className={"padding"}>
                The Owner reserves the right to make changes or updates to the Yocerebrum environment, including
                features, functionalities, and policies. Users are encouraged to review terms periodically for any
                modifications.
            </p>
            <p>
                <strong>Dispute Resolution:</strong>
            </p>
            <p className={"padding"}>
                If you are a consumer, the laws of the country in which you reside will apply to any claim, cause of
                action, or dispute you have against us that arises out of or relates to these Terms or the Yocerebrum,
                and you may resolve your claim in any competent court in that country that has jurisdiction over the
                claim. In all other cases, and for any claim, cause of action, or dispute that We file against you, you
                and Owner agree that any such claim, cause of action or dispute must be resolved exclusively in the
                courts of Isle of Man. Without prejudice to the foregoing, you agree that, in its sole discretion, We
                may bring any claim, cause of action, or dispute we have against you in any competent court in the
                country in which you reside that has jurisdiction over the claim.
            </p>
            <p>
                <strong>Content on this Website</strong>
            </p>
            <p>
                Unless where otherwise specified or clearly recognizable, all content available on this Website is owned
                or provided by the Owner or its licensors.
            </p>
            <p>
                The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no
                applicable legal provisions or third-party rights. However, it may not always be possible to achieve
                such a result.
            </p>
            <p className={"padding"}>
                In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are
                kindly asked to preferably report related complaints using the contact details provided in this
                document.
            </p>
            <p>
                <strong>Access to external resources</strong>
            </p>
            <p>
                Through this Website Users may have access to external resources provided by third parties. Users
                acknowledge and accept that the Owner has no control over such resources and is therefore not
                responsible for their content and availability.
            </p>
            <p className={"padding"}>
                Conditions applicable to any resources provided by third parties, including those applicable to any
                possible grant of rights in content, result from each such third parties’ terms and conditions or, in
                the absence of those, applicable statutory law.
            </p>
            <p>
                <strong>Acceptable use</strong>
            </p>
            <p>
                This Website and the service may only be used within the scope of what they are provided for, under
                these Terms and applicable law.
            </p>
            <p className={"padding"}>
                Users are solely responsible for making sure that their use of this Website and/or the Service violates
                no applicable law, regulations or third-party rights.
            </p>
            <p>
                <strong>Common provisions</strong>
            </p>
            <p>
                <strong>No Waiver</strong>
            </p>
            <p className={"padding"}>
                The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of
                any such right or provision. No waiver shall be considered a further or continuing waiver of such term
                or any other term.
            </p>
            <p>
                <strong>Service interruption</strong>
            </p>
            <p>
                Within the limits of law, the Owner may also decide to suspend or discontinue the operation of the
                Website altogether. If the operation of the Website is discontinued, the Owner will cooperate with Users
                to enable them to withdraw Personal Data or information and will respect Users' rights relating to
                continued product use and/or compensation, as provided for by applicable law.
            </p>
            <p className={"padding"}>
                Additionally, the operation of the Website might not be available due to reasons outside the Owner’s
                reasonable control, such as “force majeure” events (infrastructural breakdowns or blackouts etc.).
            </p>
            <p>
                <strong>Intellectual property rights</strong>
            </p>
            <p>
                Without prejudice to any more specific provision of these Terms, any intellectual property rights, such
                as copyrights, trademark rights, patent rights and design rights related to this Website are the
                exclusive property of the Owner or its licensors and are subject to the protection granted by applicable
                laws or international treaties relating to intellectual property.
            </p>
            <p className={"padding"}>
                All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks,
                illustrations, images, or logos appearing in connection with this Website are, and remain, the exclusive
                property of the Owner or its licensors and are subject to the protection granted by applicable laws or
                international treaties related to intellectual property.
            </p>
            <p>
                <strong>Changes to these Terms</strong>
            </p>
            <p>
                The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the
                Owner will appropriately inform the User of these changes.
            </p>
            <p className={"padding"}>
                Such changes will only affect the relationship with the User from the date communicated to Users
                onwards.
            </p>
            <p>
                <strong>The continued use of the Website will signify the User’s acceptance of the revised
                    Terms.</strong>
            </p>
            <p>
                If Users do not wish to be bound by the changes, they must stop using the Website.
            </p>
            <p className={"padding"}>
                The applicable previous version will govern the relationship prior to the User's acceptance. The User
                can obtain any previous version from the Owner.
            </p>
            <p>
                <strong>Assignment of contract</strong>
            </p>
            <p>
                The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all
                rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions
                regarding changes of these Terms will apply accordingly.
            </p>
            <p className={"padding"}>
                Users may not assign or transfer their rights or obligations under these Terms in any way, without the
                written permission of the Owner.
            </p>
            <p>
                <strong>Contacts</strong>
            </p>
            <p className={"padding"}>
                All communications relating to the use of this Website must be sent using the contact information stated
                in this document.
            </p>
            <p>
                <strong>Severability</strong>
            </p>
            <p className={"padding"}>
                Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law,
                the invalidity or unenforceability of such provision shall not affect the validity of the remaining
                provisions, which shall remain in full force and effect.
            </p>
        </div>
    </div>
})

export default TermsAndConditions;