import { makeAutoObservable, runInAction } from 'mobx';
import { cacheService } from './services/cacheService';
import { apiServiceV2 } from './services/apiService';
import { authorizationStore } from "./modules/authorization/authorization.store";
import { NotificationsContent } from './helpers/notifications_content';
import { NotificatonProps } from './components/modalcontainer/modalcontainer.type';
import { logoutPopupStore } from './components/logoutPopup/logoutPopup.store';
import { unityheaderStore } from './components/unityheader/unityheader.store';
import { sendMessageStore } from './modules/unityLayout/sendMessage.store';
import { uploadModelStore } from './components/Map3d/upload.store';
import { dashboardStore } from "./layout/dashboard/dashboard.store";

class GlobalStore {
    isLogin: boolean = false;
    toastStatus: boolean = false;
    toastType: string | null = null;
    otherContentModal: boolean = false;
    otherContentType: string = '';
    otherContentData: any;
    logoutLoading: boolean = false;
    guestPopupState: boolean = false;
    notification: boolean = false;
    notificationContent: NotificatonProps;
    navigation: Function
    userData: {
        "id": number,
        "username": string,
        "email": string,
        "hoory_website_token": string,
        "avatar_url": string,
        "avatar_image_url": string,
        "is_superuser": boolean,
        "has_winnings": boolean,
        "apple_verified": boolean,
        "login": string,
        "auth_provider": string,
        "balance": number,
        "vigogo_countdown": number,
        "gender": number,
        "birthday": string,
        "platforms": {},
        "isGuest": boolean
    } | any = {};

    constructor() {
        makeAutoObservable(this)
    }

    setIsLogin = (status: boolean) => {
        this.isLogin = status
    }

    setGuestPopupState = (status: boolean, redirectUrl: string = "") => {
        if(status) {
            authorizationStore.redirectUrl = redirectUrl;
        }
        this.guestPopupState = status
    }

    setToasts = (status: boolean, type: string | null) => {
        this.toastType = type
        this.toastStatus = status
        setTimeout(() => this.toastStatus = false, 5000)
    }

    setOtherContentModal = (status: boolean) => {
        this.otherContentModal = status
    }

    openOtherContentModal = (type: string, data: any) => {
        this.otherContentData = data
        this.otherContentType = type
        this.setOtherContentModal(true)
    }

    setNavigation = (func: Function) => {
        this.navigation = func
    }

    setNotifications = (status: boolean, type?: string, message?: string, time?: any) => {
        this.notification = status
        if (status && type) {
            this.notificationContent = NotificationsContent[type]
            this.notificationContent.action = this[NotificationsContent[type].action as keyof GlobalStore] as Function
            if (message) this.notificationContent.headerText!.subTitle = message
            if (time) this.notificationContent!.time = time;
        }
    }

    closePopup = () => {
        this.setNotifications(false)
    }

    navigateToMain = () => {
        this.setNotifications(false)
        this.navigation('/')
    }

    getUserData = async () => {
        try {
            const response = await apiServiceV2.getCurrentUser();
            globalStore.setIsLogin(true)
            const isSuperUser = response.data.email === "georgi.mkhitaryan@softconstruct.com";
            cacheService.set('Id', response.data.id)
            uploadModelStore.setIsSuperUser(isSuperUser)
            if (dashboardStore.authPopupActive) dashboardStore.setAuthPopupActive(false);
            this.userData = { ...response.data, token: cacheService.get('auth_token') };
        } catch (error) {
            globalStore.setIsLogin(false)
            window.location.pathname.startsWith("/dashboard/myspaces") && globalStore.navigation("/");
            console.log(error);
        }
    }

    saveUserData = async (data: {
        username: string,
        hooryToken: string,
        gender: number,
        birthday: string
    }) => {
        const response = await apiServiceV2.editUserData(data)
        if (!response.error) {
            runInAction(() => {
                this.userData = {
                    ...this.userData,
                    ...response.data,
                    hooryWebsiteToken:response?.data.hooryToken
                };
            })
            globalStore.setToasts(true, 'its_success');
        } else {
            globalStore.setToasts(true, 'username_exist');
            console.warn('ERROR');
        }
    }

    projectConfiguration = async () => {
        if(!!cacheService.get("auth_token")) await this.getUserData();
    }

    clearData = () => {
        this.userData = {}
        authorizationStore.userData = {}
    }

    logout = async () => {
        this.logoutLoading = true;
        try {
            await apiServiceV2.logOut();
            cacheService.clear();
            logoutPopupStore.setShowLogoutPopup(false)
            this.userData = null;
            this.isLogin = false
            this.navigation("/")
        } catch (error) {
            console.log(error);

        }
        this.logoutLoading = false

    }

    dubleLoginLogut = () => {
        sendMessageStore.sendMessage && sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${cacheService.getRoomId}`);
        unityheaderStore.onLeaveRoom()
        cacheService.clear();
        this.navigation("/")
        this.logoutLoading = false
        logoutPopupStore.setShowLogoutPopup(false)
    }

    setUserData = (data: any) => {
        this.userData = data;
    }

}

export const globalStore: GlobalStore = new GlobalStore()