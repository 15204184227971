import {makeAutoObservable} from "mobx";

class LogoutPopupStore {
    showLogoutPopup: boolean = false;
    constructor() {
        makeAutoObservable(this)
    }
    setShowLogoutPopup = (state: boolean) => {
        this.showLogoutPopup = state;
    }
}

export const logoutPopupStore = new LogoutPopupStore()