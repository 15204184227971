import {observer} from "mobx-react-lite";
import {editAvatarStore} from "../editAvatar/editAvatar.store";
import AvatarContent from "./AvatarContent";
import {Avaturn} from "./AvaturnSDK";
import React from "react";
import "./style.scss"

const AvatarEditor = observer(() => {
    const {avatarEditor, setAvatarEditor} = editAvatarStore;
    const handleClose = () => {
        setAvatarEditor("")
    }
    return (
        <div className="avatarContainerWrapper">
            <div className="avatarContainerCloseButton" onClick={handleClose}>
                <i className="icon-close-circled"/>
            </div>
            {avatarEditor === "rpm" ? <AvatarContent setToggleChange={handleClose}/> :
                <Avaturn setToggleChange={handleClose}/>}
        </div>
    )
})

export default AvatarEditor;