import { observer } from "mobx-react-lite";
import { ticketupload } from "../ticketupload.store";
// import { useCallback } from "react";
// import { useDropzone } from "react-dropzone";
// import {ReactComponent as RemoveSvg} from "../../../assets/icons/delete.svg";
import styles from '../styles.module.scss';
// import cn from "classnames";
import Button from "../../../ui/button";
import "../../modalcontainer/bodyContent/roomUpload/sections/uploadImage/styles.scss"

const UploadBody = observer(() => {
    const {
        // progress,
        // fileOptions,
        // hasTicketFile,
        // hasTicketNumber,
        ticketNumberValue,
        errorMsg,
        // file,
        // onDropTicket,
        onInputTicketNumber,
        sendTicketNumber,
        // onRemoveTicketFile,
        // eventData,
        disabled
    } = ticketupload;
    // const onDrop = useCallback(onDropTicket, [])

    // const {getRootProps, getInputProps} = useDropzone({
    //     accept: {
    //         // 'image/heif': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
    //         "application/pdf": [".pdf"]
    //     },
    //     noDrag: progress > 0,
    //     disabled: progress > 0,
    //     onDrop
    // });

    return (
        <div className={styles.ticketUpload}>
            <div className={styles.ticketUploadTitleRow}>
                <span className={styles.ticketUploadTitleText}>
                    To join the event please submit the 10-digit number that is right under the QR code of your ticket!
                </span>
                {/*<a target="_blank" className={styles.uploadLink} href={eventData.eventProperty.ticketBuyingUrl}>fastticket.am</a>*/}
            </div>
            {/*<div className={styles.uploadMainWrpHolder} >*/}
            {/*    <div */}
            {/*        className={*/}
            {/*            cn({*/}
            {/*                [styles.uploadMainWrp]: true, */}
            {/*                [styles.disabled]: hasTicketNumber, */}
            {/*                [styles.error]: errorMsg.file, */}
            {/*                [styles.hidden]: file*/}
            {/*            })*/}
            {/*        }*/}
            {/*        {...getRootProps()}*/}
            {/*    >*/}
            {/*        <div className={styles.smallUploadHolder}>*/}
            {/*            <i className={cn('icon-upload1',  styles.uploadIcon)} />*/}
            {/*            <p className={styles.uploadMainText}>*/}
            {/*                Upload FIle*/}
            {/*            </p>*/}
            {/*            <div className={styles.smallContainerOr}>*/}
            {/*                <p className={styles.ticketUploadTitleText}>or</p>*/}
            {/*            </div>*/}
            {/*            <Button size='rounded-30 sm' color='secondaryGhost'>*/}
            {/*                <span className={styles.buttonTxt}>Browse</span>*/}
            {/*            </Button>*/}
            {/*            <input {...getInputProps()} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={cn({[styles.filNameHolder]: true, [styles.active]: file})} >*/}
            {/*        <div className={styles.filNameTopHolder}>*/}
            {/*            <div className={styles.filNameHeadRow}>*/}
            {/*                <p className={styles.filNameHeadRowText}>*/}
            {/*                    {fileOptions.name}*/}
            {/*                </p>*/}
            {/*                <div className={cn({[styles.filNameRowIconCase]: true, [styles.selected]: hasTicketFile})} onClick={()=>onRemoveTicketFile()}>*/}
            {/*                    <RemoveSvg className={cn({[styles.icon_delete]: true, [styles.deleteIcon]: hasTicketFile})}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <p className={styles.bottomText}>{fileOptions.size} mb</p>*/}
            {/*        </div>*/}
            {/*        <div className={styles.progressHolder}>*/}
            {/*            <div className={styles.progressBar}>*/}
            {/*                <div className={styles.progressThumb} style={{width: `${progress}%`}} />*/}
            {/*            </div>*/}
            {/*            <div className={styles.progressPercent}>*/}
            {/*                <span className={styles.progressPercentText}>{progress}%</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        */}
            {/*        <p className={styles.errorText}>*/}
            {/*            {errorMsg.file}*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <p className={styles.ticketUploadTitleText}>*/}
            {/*        /!*Allowed file types: jpg, jpeg, png, pdf, heic, heif*!/*/}
            {/*        Allowed file types: pdf*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<p className={styles.uploadWhiteText}>Or</p>*/}
            <div className={styles.inputWrp}>
                <p className={styles.inputText}>
                    Ticket number
                </p>
                <div className="inputCase general-gradient-border">
                    <input value={ticketNumberValue} onChange={(e)=>onInputTicketNumber(e.target.value)} className="input" type="number" placeholder="1234789"/>
                    <div className="inputCaseAction">
                        <Button type="rounded-12" disabled={!ticketNumberValue || !!errorMsg.number || disabled} size='circledSmall' color='primary' onClick={sendTicketNumber}>
                            <i className="circleButtonIcon icon-right"/>
                        </Button>
                    </div>
                </div>
                <p className={styles.errorText}>
                    {errorMsg.number}
                </p>
            </div>
        </div>
    );
})

export default UploadBody;
