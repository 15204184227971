import {makeAutoObservable} from "mobx";

class PdfStore {
    pdfUrl: string = "";
    constructor() {
        makeAutoObservable(this);
    }

    setPdfUrl = (val: string) => {
        this.pdfUrl = val;
    }
}

export const pdfStore = new PdfStore();