import { makeAutoObservable } from "mobx";
import {
    NftDataType,
    TCallData,
    TPlayerUserData,
    TPortalData,
    UnityLayout,
} from "./unityLayout.type";
import {
    customSort,
    getKickUserTime,
} from "../../helpers/utils";
import { cacheService } from "../../services/cacheService";
import { apiServiceV2 } from "../../services/apiService";
import {
    CallStatus,
    MICROPHONE_STATUS,
} from "../scenetemplate/hooks/constants";
import { globalStore } from "../../global.store";
import { scenetemplateStore } from "../scenetemplate/stores/scenetemplate.store";
import { ticketupload } from "../../components/TicketUpload/ticketupload.store";
import { ucraftStore } from "../../ucraft.store";
import { privateTalkStore } from "../scenetemplate/stores/privateTalk.store";
import { sendMessageStore } from "./sendMessage.store";
import { agoraStore } from "../scenetemplate/stores/agora.store";
import { reserveTablePopupsStore } from "../../components/reserveTablePopups/reserveTablePopups.store";
import { roomModelControllerStore } from "../../components/roomModelController/roomModelController.store";
import { popokGameStore } from "../../components/PopokGame/community.store";
import { shopEcommerceStore, TShopItem } from "../../components/ShopEcommerce/shopEcommerce.store";

import { pdfStore } from "../../components/pdfIframe/pdf.store";
import { unityfooterStore } from "../../components/unityfooter/unityfooter.store";
import {participantsStore} from "../../components/participents/participants.store";
import {checkEventIsEnded, checkEventsDates, isVisitor} from "../../helpers/isVisitor";
import {logoutPopupStore} from "../../components/logoutPopup/logoutPopup.store";
import {isMobile} from "react-device-detect";
import {cardsblockStore} from "../../components/cardsblock/cardsblock.store";

const {
    privateCallHandler,
    inviteHandler,
    responseCallUser,
    leavePrivateCall,
} = privateTalkStore;


class UnityLayoutStore {
    isLoaded: boolean = false;
    hideView: boolean = false;
    roomData: any = {};
    hooryToken: string;
    roomUsersState: UnityLayout.RoomUsersState[] = [];
    searchValue: string = "";
    filteredUsers: UnityLayout.RoomUsersState[] = [];
    blockedUsers: any = [];
    roomId: string;
    roomDataStatus: boolean = false;
    isActiveControllers: boolean = false;
    locked: boolean = false;
    avatarPosition: any;
    controllersProps: any;
    portalData: TPortalData | null;
    unityLoaded: boolean = false;
    micPermission: boolean = false;
    loadingProgression: number = 0;
    nftData: NftDataType;
    openUrl: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    get getMyOptions() {
        const res = this.roomUsersState.filter(
            (user) => user.publicId == cacheService.getMyId
        )[0];
        return { ...res, isCreator: this.isMyRoom };
    }

    get renderRoomUsers() {
        if (this.searchValue) {
            return this.filteredUsers;
        } else {
            return this.roomUsersState;
        }
    }

    get isMyRoom() {
        const res = this.roomData?.creator?.id == cacheService.getMyId;
        return res;
    }

    setLoadingProgression = (num:number)=> {
        this.loadingProgression = Math.floor(num * 100);
    }

    onSceneFullyLoaded = () => {
        this.loadingProgression = 0;
        this.isLoaded = true;
    };

    setRooomData = (name: string) => {
        this.roomData.name = name;
    };

    setNftData = (data: NftDataType) => {
        this.nftData = data;
    }

    onLikeRoom = async (roomId: string | undefined) => {
        const res = await apiServiceV2.handleLikeRoom(Number(roomId));
        if (!res.error) {
            this.roomData = {
                ...this.roomData,
                isLiked: res.data.isLiked === 1,
                likesTotalCount: this.roomData.likesTotalCount + (res.data.isLiked === 1 ? 1 : -1),
            };
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
    };


    userIsCreator = (id: number) => {
        const res = this.roomData.creator.id == id;
        return res;
    };

    setHooryToken = (token: string) => {
        this.hooryToken = token
    }

    setBlockHooryClicks(bool: boolean) {
        // this.blockHooryClicks = bool
    }


    setRoomData = (roomData: any) => {
        participantsStore.setAdminUsers(roomData.admins);
        this.blockedUsers = roomData.blockedUsers;
        this.setHooryToken(roomData.hooryWebsiteToken)
        return (this.roomData = roomData);
    };

    searchHandler = (val: string) => {
        this.searchValue = val;
        if (!!val.length) {
            const filteredUsers = [...customSort(this.roomUsersState, val)];
            this.filteredUsers = filteredUsers;
        } else {
            this.filteredUsers = [];
        }
    };

    onSceneLoaded = (...data: any) => {
        console.log(data);
    };

    onSceneUnLoaded = (...data: any) => {
        console.log(data);
    };

    onObjectSelected = (publicId: number, type: number, coordinate: string, url: string, isSelected: number) => {
        this.setControllersProps({
            publicId,
            type,
            coordinate,
            url,
            isSelected,
        });
        roomModelControllerStore.setInitControllers({
            publicId,
            type,
            coordinate: JSON.parse(coordinate),
            url
        })
        this.setLocked(!!isSelected);
        this.setIsActiveControllers(true);
    };

    handleLock = () => {
        sendMessageStore.sendMessage("ReactManager", "ToggleLockObject");
        this.setControllersProps({
            ...this.controllersProps,
            isSelected: !this.locked
        });
        this.setLocked(!this.locked);
    };

    handleDownload = () => {
        fetch(this.controllersProps.url, {
            method: "GET",
            headers: {
                "Content-Type": "application/*",
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `file.${this.controllersProps.url.split(".").pop()}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            });
    };

    onObjectDeselected = () => {
        this.setIsActiveControllers(false);
        if (unityfooterStore.showModelController) {
            unityfooterStore.setShowModelController(false);
        }
    };

    setLocked = (bool: boolean) => {
        this.locked = bool;
    };

    onReturnCurrentUsersIds = (...data: any) => {
        console.log(data);
    };

    onSetAsEnvironment = (...data: any) => {
        console.log(data);
    };

    onOpenPortalToSpace = (roomId: number, name: string, image: string) => {
        sendMessageStore.lockKeyboardInput();
        this.portalData = { roomId, name, image };
    };

    removePortalData = () => {
        this.portalData = null;
        sendMessageStore.unLockKeyboardInput();
    }

    clearRoomUsersState = () => {
        this.roomUsersState = []
    }

    onPortalTo = async(leave:any) => {
        await leave()
        this.roomData.previewImage = this.portalData?.image;
        this.roomData.name = this.portalData?.name;
        this.isLoaded = false;

        sendMessageStore.sendMessage(
            "ReactManager",
            "UnloadSpace",
            `${this.roomId}`
        );
        this.roomDataStatus = false;
        this.clearRoomUsersState();
        globalStore.navigation(`/room/${this.portalData?.roomId}`);
        this.removePortalData()
    }

    setIsActiveControllers = (bool: boolean) => {
        this.isActiveControllers = bool;
    }

    setControllersProps = (data: any) => {
        this.controllersProps = data;
    }

    onUploadNft = (...data: any) => {
        console.log(data);
    };

    onZoomNft = (data: string) => {
        this.setNftData(JSON.parse(data));
    };

    onDeleteNft = (...data: any) => {
        console.log(data);
    };

    onCurrentTransformCommandIndexChanged = (step: any) => {
        roomModelControllerStore.setCurrentStep(step)
    };

    onTransformCommandsCountChanged = (step: any) => {
        roomModelControllerStore.setEndStep(step)
    };

    onAddUserAudioState = (
        username: string,
        publicId: number,
        talkingValue: number,
        micValue: number,
        freezedValue: boolean,
        haveMic: number,
        isGuest: number,
        privateCallState: number,
        isFastBankUser: number,
        micPermissions: number,
        micPermissionGranted: number,
        email: string,
    ) => {
        const data: UnityLayout.RoomUsersState = {
            username,
            publicId,
            email,
            talkingValue,
            micValue,
            freezedValue: unityLayoutStore.roomData.freezeUsers.some((freezeUser: any) => freezeUser.id == publicId),
            haveMic,
            isGuest: Boolean(isGuest),
            privateCallState,
            isFastBankUser,
            micPermissions,
            micPermissionGranted,
            isCreator: this.userIsCreator(publicId),
            isAdmin: participantsStore.userIsAdmin(publicId),
        };
        this.roomUsersState = [...this.roomUsersState, data];
    };

    onFreezeStateForUserChanged = (id: any, state: any) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === Number(id)) {
                participantsStore.user.freezedValue = Boolean(state);
                user.freezedValue = Boolean(state);
            }
            return user;
        });
        if (id === cacheService.getMyId) {
            if (Boolean(state)) {
                globalStore.setNotifications(true, "frozen");
                agoraStore.videoEnabled && scenetemplateStore.videoToggle();
                agoraStore.micEnabled && scenetemplateStore.audioToggle();
            } else {
                globalStore.setNotifications(true, "unfrozen");
            }
        }
    };

    onMicStateForUserChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map(user => {
            if (user.publicId === id) {
                participantsStore.user.talkingValue = state;
                user.talkingValue = state;
            }
            return user;
        })

        if (id === cacheService.getMyId) {
            return scenetemplateStore.audioToggle(state)
        }
    };

    onSpeakingStateForUserChanged = (...data: any) => {
        console.log(data);
    };

    onRemoveUserAudioState = (id: number) => {
        this.roomUsersState = this.roomUsersState.filter(
            (user) => user.publicId !== id
        );
    };

    onUserLeaved = (...data: any) => {
        console.log(data, "Leaved");
    };

    onSelectWheel = (...data: any) => {
        console.log(data);
    };

    onOpenBanner = (...data: any) => {
        console.log(data);
    };

    onOpenBook = (...data: any) => {
        pdfStore.setPdfUrl(data[0]);
        // const pdfId = data[0]
        // if (pdfId) {
        //     const response = await apiServiceBase.getBooks(data[0])
        //     if (response.success) {
        //
        //     }
        // }
    };

    onOpenSlotGame = (url: string) => {
        const token = globalStore.userData.externalToken;
        const gameUrl = url + `&externalToken=${token}`
        popokGameStore.setGameUrl(gameUrl)
    };

    onOpenGamblingTable = (data: any) => {
        // console.log(JSON.parse(data));
    };

    onOpenEcommerceProduct = (data: any) => {
        const res: TShopItem = JSON.parse(data)
        shopEcommerceStore.setShopData(res)
        shopEcommerceStore.setShopPopup(true)

    };

    OnOpenHooryForUser = (...data: any) => {
        console.log(data);
    };

    onOpenHooryWidget = (...data: any) => {
        console.log(data);
    };

    OnSelectionTransformChange = (...data: any) => {
        console.log(data);
    };

    onBlockUser = (userId: number, roomId: number) => {
        if (userId === Number(cacheService.get("Id"))) {
            globalStore.setNotifications(true, "block");
            this.isLoaded = false;
            this.roomData = {};
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${roomId}`);
            globalStore.navigation("/");
        }
    };

    onUserMicAvailable = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.micIsExist = Boolean(state);
                user.micIsExist = Boolean(state);
            }
            return user;
        });
    };

    onReturnNearestTransform = (data: any) => {
        let position = JSON.parse(data);
        this.avatarPosition = JSON.stringify(position);
    };

    OnPhotonConnectionError = (...data: any) => {
        console.log(data);
    };

    userInCall = (id: number) => {
        const inCall =
            this.roomUsersState.filter(
                (user) => user.publicId === id
            )[0].privateCallState !== 0;
        if (inCall){
            globalStore.setToasts(true, "inprivatcall");
        }
        return inCall
    }

    onOpenUserMenu = (...data: any) => {
        const playerData: TPlayerUserData = JSON.parse(data);
        const inCall = this.userInCall(playerData.public_id)

        if(inCall) return null
        privateCallHandler({
            public_id: playerData.public_id,
            username: playerData.username,
        });
    };

    onInviteToAgoraChanel = (data: string) => {
        const callData: TCallData = JSON.parse(data);
        if (cacheService.getMyId === callData.target_user) {
            const sender = this.roomUsersState.filter(
                (user) => user.publicId === callData.sender_user
            )[0];
            inviteHandler(callData, sender);
        }
    };

    onAgoraIndividualInviteAnswer = (...data: any) => {
    };

    onFullScreenShare = (...data: any) => {
        console.log(data);
    };

    onPrivateCallStateChanged = (id: number, state: CallStatus) => {
        this.roomUsersState = this.roomUsersState.map((user) => {
            if (user.publicId === id) {
                participantsStore.user.privateCallState = state;
                user.privateCallState = state;
            }
            return user;
        });
        responseCallUser(id, state);
    };

    onRemovedFromCall = () => {
        leavePrivateCall();
    };

    onLoaderUnitLoaded = (e: any) => {
    };

    onKickOffUser = (userId: number, roomId: number, message: string) => {
        if (userId === Number(cacheService.get("Id"))) {
            const time = message.split("kicked for ")[1];
            const hours = time.endsWith("hours") ? time.split(" hours")[0] : 0;
            const minutes = time.endsWith("minutes") ? time.split(" minutes")[0] : 0;
            const data = {
                hours: hours,
                minutes: minutes
            }
            globalStore.setNotifications(
                true,
                "start_kicked",
                `You have been kicked for`,
                data
            );
            this.isLoaded = false;
            this.roomData = {};
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${roomId}`);
            globalStore.navigation("/");
        }
    };

    onOpenRaffle = (...data: any) => {
        console.log(data);
    };

    onGuestOpenRaffle = (...data: any) => {
        console.log(data);
    };

    onOpenBannerSaleNft = (...data: any) => {
        console.log(data);
    };

    onOpenCerebrumNft = (...data: any) => {
        console.log(data);
    };

    onUserJoined = (username: string) => {
        if(username === globalStore.userData.username){
            sendMessageStore.sendMessage("ReactManager", "UnloadSpace", `${cacheService.getRoomId}`);
            // globalStore.logout()
            cacheService.clear();
            logoutPopupStore.setShowLogoutPopup(false)
            globalStore.userData = null;
            globalStore.isLogin = false
            globalStore.navigation("/")
            globalStore.setNotifications(true, 'duble_login');
        }
    };

    onMicrophoneUseAbilityChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user)=> {
            if(user.publicId === id){
                participantsStore.user.micPermissions = state;
                user.micPermissions = state;
            }
            return user;
        })
    };

    onRoomMicrophoneUseAbilityChanged = () => {
        this.micPermission = !this.micPermission;
    };

    onVoucherCopied = (...data: any) => {
        console.log(data);
    };

    onVigogoExploreExpired = (...data: any) => {
        console.log(data);
    };

    onMicrophonePermissionChanged = (id: number, state: number) => {
        this.roomUsersState = this.roomUsersState.map((user)=> {
            if(user.publicId === id){
                participantsStore.user.micPermissionGranted = state;
                user.micPermissionGranted = state;
            }
            return user;
        })
    };

    onLeaveFromGame = (...data: any) => {
        console.log(data);
    };

    onHideView = (...data: any) => {
        this.isLoaded = true;
        this.hideView = true;
        console.log(data);
    };

    onPhotonConnectionIsReady = (...data: any) => {
        this.unityLoaded = true;

    };

    onOpenPetanqueEventPage = () => {
        // unityheaderStore.onLeaveRoom();
        this.onOpenPortalToSpace(829694783, "Tamada's Virtual Concert at Petanque Digital Club", "https://fv-media.storage.googleapis.com/room_preview_image/P03.png")
    };

    onOpenUrl = (url: string, openIframe: boolean) => {
        if(!openIframe) return window.open(url);
        return this.setOpenUrl(url)
    };

    setOpenUrl = (url: string) => {
        this.openUrl = url;
    }

    onUserAdminRollChanged = (publicId: number) => {
        const condidate = participantsStore.adminUsers.filter((user: any) => user.id == publicId)[0];
        if (!condidate) {
            const curUser = this.roomUsersState.filter(
                (user: any) => user.publicId == publicId
            )[0];
            participantsStore.setAdminUsers([...participantsStore.adminUsers, {
                username: curUser.username,
                id: curUser.publicId
            }])
            if (cacheService.getMyId === publicId) globalStore.setNotifications(true, 'admin')
        } else {
            participantsStore.adminUsers = participantsStore.adminUsers.filter(
                (user: any) => user.id != publicId
            );
            if (cacheService.getMyId === publicId) globalStore.setNotifications(true, 'remove_admin')
        }
        unityLayoutStore.roomUsersState.map((user: any) => {
            return {
                ...user,
                isAdmin: !!condidate,
            }
        })
    }

    unityListeners = (): any => {
        return {
            onSceneLoaded: this.onSceneLoaded,
            onSceneFullyLoaded: this.onSceneFullyLoaded,
            onSceneUnLoaded: this.onSceneUnLoaded,
            onObjectSelected: this.onObjectSelected,
            onObjectDeselected: this.onObjectDeselected,
            onSetAsEnvironment: this.onSetAsEnvironment,
            onOpenPortalToSpace: this.onOpenPortalToSpace,
            onUploadNft: this.onUploadNft,
            onZoomNft: this.onZoomNft,
            onDeleteNft: this.onDeleteNft,
            onCurrentTransformCommandIndexChanged: this.onCurrentTransformCommandIndexChanged,
            onTransformCommandsCountChanged: this.onTransformCommandsCountChanged,
            onAddUserAudioState: this.onAddUserAudioState,
            onFreezeStateForUserChanged: this.onFreezeStateForUserChanged.bind(this),
            onMicStateForUserChanged: this.onMicStateForUserChanged,
            onSpeakingStateForUserChanged: this.onSpeakingStateForUserChanged,
            onRemoveUserAudioState: this.onRemoveUserAudioState,
            onUserLeaved: this.onUserLeaved,
            onSelectWheel: this.onSelectWheel,
            onOpenBanner: this.onOpenBanner,
            onOpenBook: this.onOpenBook,
            onOpenSlotGame: this.onOpenSlotGame,
            onOpenGamblingTable: this.onOpenGamblingTable,
            onOpenEcommerceProduct: this.onOpenEcommerceProduct,
            OnOpenHooryForUser: this.OnOpenHooryForUser,
            onOpenHooryWidget: this.onOpenHooryWidget,
            OnSelectionTransformChange: this.OnSelectionTransformChange,
            onBlockUser: this.onBlockUser.bind(this),
            onUserMicAvailable: this.onUserMicAvailable,
            onReturnNearestTransform: this.onReturnNearestTransform,
            OnPhotonConnectionError: this.OnPhotonConnectionError,
            onOpenUserMenu: this.onOpenUserMenu,
            onInviteToAgoraChanel: this.onInviteToAgoraChanel,
            onAgoraIndividualInviteAnswer: this.onAgoraIndividualInviteAnswer,
            onFullScreenShare: this.onFullScreenShare,
            onPrivateCallStateChanged: this.onPrivateCallStateChanged,
            onLoaderUnitLoaded: this.onLoaderUnitLoaded,
            onRemovedFromCall: this.onRemovedFromCall,
            onKickOffUser: this.onKickOffUser,
            onOpenRaffle: this.onOpenRaffle,
            onGuestOpenRaffle: this.onGuestOpenRaffle,
            onOpenBannerSaleNft: this.onOpenBannerSaleNft,
            onOpenCerebrumNft: this.onOpenCerebrumNft,
            onUserJoined: this.onUserJoined,
            onMicrophoneUseAbilityChanged: this.onMicrophoneUseAbilityChanged,
            onRoomMicrophoneUseAbilityChanged: this.onRoomMicrophoneUseAbilityChanged,
            onVoucherCopied: this.onVoucherCopied,
            onVigogoExploreExpired: this.onVigogoExploreExpired,
            onMicrophonePermissionChanged: this.onMicrophonePermissionChanged,
            onLeaveFromGame: this.onLeaveFromGame,
            onHideView: this.onHideView,
            onPhotonConnectionIsReady: this.onPhotonConnectionIsReady,
            onOpenPetanqueEventPage: this.onOpenPetanqueEventPage,
            onUserAdminRollChanged: this.onUserAdminRollChanged,
            onReserveTable: reserveTablePopupsStore.onReserve,
            onOpenUrl: this.onOpenUrl,
        };
    };

    hasUserOnFreeze = (id = cacheService.getMyId) => {
        return this.roomUsersState.filter((user) => user.publicId === id)[0]
            ?.freezedValue;
    };

    muteAllUsers = () => {
        sendMessageStore.sendMessage("ReactManager", "SetOtherUserMicrophoneState", JSON.stringify({id: 0, mic: false}));
    }

    micActiveHandler = () => {
        sendMessageStore.sendMessage("ReactManager", "GrantMicUseAbilityToOtherUsers", JSON.stringify({micPermissionGranted:!this.micPermission, userIds: null}));
        this.onRoomMicrophoneUseAbilityChanged()
    }

    handleFreeze = async (user: UnityLayout.RoomUsersState, roomId: number) => {
        const event = user.freezedValue
            ? MICROPHONE_STATUS[0]
            : MICROPHONE_STATUS[2];
        try {
            if (event === "FREEZE") {
                await apiServiceV2.freezeUser(
                    {
                        userIds: [user.publicId]
                    }
                );
                sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                    command: "11",
                    data: {
                        "userId": user.publicId,
                        "state": true,
                    }
                }))
            } else {
                await apiServiceV2.unfreezeUser(user.publicId);
                sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                    command: "11",
                    data: {
                        "userId": user.publicId,
                        "state": false,
                    }
                }))
            }
            this.roomUsersState = this.roomUsersState.map((item) => {
                if (item.publicId === user.publicId) {
                    participantsStore.user.freezedValue = !user.freezedValue;
                    item.freezedValue = !user.freezedValue;
                }
                return item;
            });
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    makeAdminHandler = async (
        user: UnityLayout.RoomUsersState,
    ) => {
        try {
            await apiServiceV2.makeUserAdmin({
                userIds: [user.publicId],
            });
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "10",
                data: {
                    "admins": [
                        {
                            "public_id": Number(user.publicId),
                            "username": user.username
                        }
                    ],
                    "room_public_id": Number(unityLayoutStore.roomData.id),
                }
            }))
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "10",
                data: {
                    "admins": [
                        {
                            "public_id": Number(user.publicId),
                            "username": user.username
                        }
                    ],
                    "userId": Number(user.publicId),
                    "email": user?.email,
                    "username": user.username,
                    "roomId": Number(unityLayoutStore.roomData.id),
                    "IsAdmin": true,
                }
            }))
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    

    handleBlockUser = async (
        user: UnityLayout.RoomUsersState | undefined,
        roomId: string | undefined
    ) => {
        try {
            await apiServiceV2.blockUser({
                userIds: [user?.publicId as number],
            }, roomId);
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "9",
                data: {
                    "room_public_id": unityLayoutStore.roomData.id,
                    "user_public_id": user?.publicId,
                }
            }))
            this.blockedUsers = [
                ...this.blockedUsers,
                { id: user?.publicId, username: user?.username },
            ];
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    handleUnBlockUser = async (uid: any, roomId: string | undefined) => {
        try {
            await apiServiceV2.unBlockUser(roomId, uid);
            sendMessageStore.sendMessage("ReactManager", "ExecuteCommand", JSON.stringify({
                command: "9",
                data: {
                    "room_public_id": roomId,
                    "user_public_id": uid,
                }
            }))
            this.blockedUsers = this.blockedUsers.filter(
                (user: any) => user.id !== uid
            );
        } catch (error) {
            console.log(error);
        }
    };

    removeState = () => {
    };

    hasEventRoom = (room: any): boolean => {
        return !!room?.eventData.length;
    };

    getRoomData = async (id: string = "") => {
        const myId = cacheService.getMyId
        this.roomId = id;
        const res: any = await apiServiceV2.getRoomByID(id);

        if (!res.error) {
            const blockedUser = res.data.blockedUsers.filter((user: any) => user.id == myId)
            const kickedUser = res.data.kickedUsers.filter((user: any) => user.id == myId)
            if (blockedUser.length) {
                this.roomDataStatus = false;
                return globalStore.setNotifications(true, "start_block");
            }
            if (kickedUser.length) {
                const dateString = getKickUserTime(kickedUser[0].remove_kick_date);
                let time: string = "";
                if (dateString.hours > 0) time += `${dateString.hours} hours`;
                if (dateString.minutes > 0)
                    time += ` ${dateString.minutes} minutes`;
                if (dateString.seconds > 0)
                    time += ` ${dateString.seconds} seconds`;
                if (time.length) return globalStore.setNotifications(
                    true,
                    "start_kicked",
                    `You have been kicked for`,
                    dateString
                );
            }

            cacheService.set("roomId", id);
            const { eventData, creator, admins, roomType, visitors } = res.data;
            if (!globalStore.isLogin && (roomType === 18 || roomType === 17 || roomType === 16)) {
                cacheService.clear();
                globalStore.setIsLogin(false);
                globalStore.navigation(
                    "/"
                );
                return globalStore.setGuestPopupState(true, `/room/${id}`);
            }
            const isCreator = +creator.id === +globalStore.userData.id;
            const isAdmin = admins.some((admin: any) => +admin.id === +globalStore.userData.id);
            if (
                !eventData[0] ||
                eventData[0].eventType === 1 ||
                (eventData[0].eventType === 2 &&
                    (isVisitor(visitors, globalStore.userData.id) && checkEventsDates(eventData[0]?.eventStartDate, eventData[0]?.eventEndDate))) ||
                (eventData[0].eventType === 2 && checkEventIsEnded(eventData[0]?.eventEndDate)) ||
                isCreator ||
                isAdmin) {
                if(isMobile) {
                    return cardsblockStore.setOpenInAppData(res.data)
                } else {
                    this.setRoomData(res.data);
                    this.roomDataStatus = true;
                }
            } else {
                if (!globalStore.isLogin) {
                    cacheService.clear();
                    globalStore.setIsLogin(false);
                    globalStore.navigation(
                        "/"
                    );
                    return globalStore.setGuestPopupState(true, `/room/${id}`);
                }
                // globalStore.navigation("/");
                ticketupload.uploadTicketPopup(eventData[0], +id, visitors);
            }
            if (res.data.ecommerce_domain && res.data.ecommerce_id) {
                ucraftStore.setEcommerceData(
                    res.data.ecommerce_domain,
                    res.data.ecommerce_id
                );
                ucraftStore.setEcommerceConnected(true);
            }
        } else {
            if (res.message === 'Authentication failed.' || res.message === "Token is not found or expired.") {
                cacheService.clear();
                globalStore.setIsLogin(false);
                globalStore.navigation(
                    "/"
                );
                return globalStore.setGuestPopupState(true, `/room/${id}`);

            }
            this.roomDataStatus = false;
            if (res?.errors?.code === 69) {
                let time: string = "";
                if (res.errors.hours) time += `${res.errors.hours} hours`;
                if (res.errors.minutes)
                    time += ` ${res.errors.minutes} minutes`;
                if (res.errors.seconds)
                    time += ` ${res.errors.seconds} seconds`;
                // globalStore.setNotifications(
                //     true,
                //     "start_kicked",
                //     `You have been kicked for`,
                //     dateString
                // );
            } else {
                globalStore.setNotifications(true, "room_error");
            }
        }
    };
}

export const unityLayoutStore = new UnityLayoutStore();
