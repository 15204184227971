import { makeAutoObservable } from 'mobx';
import { apiServiceV2 } from '../../services/apiService';

class UploadStore {
    isSuperUser:boolean = false;
    file: File | null = null;
    fileName: string = '';
    fileUrl: string = '';
    size: string = '0';
    errorMsg: string = '';
    uploadModal: boolean = false;
    dragActive: boolean = false;
    progress: number = 0;

    constructor() {
        makeAutoObservable(this)
    }

    setDragActive = (bool: boolean) => {
        this.dragActive = bool;
    }
    setFile = (file: File | null) => {
        if (file) {
            this.file = file;
            this.fileName = file.name
            this.size = `${file.size}`;
            this.progress = 100
            const reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onloadend = () => {
                this.fileUrl = reader.result as string
            }
        }
    }


    onSave = async()=> {
        const sendData: FormData = new FormData()
        sendData.append("model", this.file as File)
        sendData.append("positionX", '0')
        sendData.append("positionY", '0')
        sendData.append("positionZ", '0')
        sendData.append("rotationX", '0')
        sendData.append("rotationY", '0')
        sendData.append("rotationZ", '0')
        sendData.append("scaleX", '1')
        sendData.append("scaleY", '1')
        sendData.append("scaleZ", '1')
        await apiServiceV2.addModels(sendData)
    }

    uploadModalToggle = () => {
        this.uploadModal = !this.uploadModal;
    }

    setProgress = (num: number) => {
        this.progress = num
    }

    setIsSuperUser = (bool:boolean)=> {
        this.isSuperUser = bool;
    }

    onRemoveFile = () => {
        this.file = null;
        this.fileName = ''
        this.size = ''
        this.errorMsg = ''
        this.fileUrl = ''
        this.uploadModal = false
    }

}
export const uploadModelStore = new UploadStore();