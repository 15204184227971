import {makeAutoObservable, reaction} from "mobx";
import {unityLayoutStore} from "../../modules/unityLayout/unityLayout.store";
import {emojis} from "../../helpers/emojis";
import {ReactComponent as AnimationsSvg} from "../../assets/icons/animations.svg";
import {ReactComponent as EmojiSvg} from "../../assets/icons/emoji.svg";
import {ReactComponent as SettingSvg} from "../../assets/icons/settings.svg";
import {ReactComponent as MoreSvg} from "../../assets/icons/more.svg";
import {ReactComponent as InviteUserSvg} from "../../assets/icons/inviteUser.svg";
import {ReactComponent as ScreenshotSvg} from "../../assets/icons/screenshot.svg";
import "./unityheaderView/unityheader.scss";
import {TSettingsOptionsList} from "./unityheader.type";
import {inviteToRoomStore} from "../modalcontainer/bodyContent/inviteToRoom/inviteToRoom.store";
import {saveAsTemplateStore} from "../modalcontainer/bodyContent/saveAsTemplate/saveAsTemplate.store";
import {isAdmin, isCreator} from "../../helpers/isAdminOrCreator";
import {apiServiceV2} from "../../services/apiService";
import React, {ReactElement} from "react";
import {Approved} from "../modalcontainer/bodyContent/approved";
import {resetAllChangesStore} from "../modalcontainer/bodyContent/resetAllChanges/resetAllChanges.store";
import {ucraftStore} from "../../ucraft.store";
import {cacheService} from "../../services/cacheService";
import {actionpanelStore} from "../actionpanel/actionpanel.store";

class Unityheader {
    emojies = emojis();
    settingOptions: TSettingsOptionsList[];
    openAvatarActions: boolean = false;
    openInviteUserPopup: boolean = false;
    emojisPopup: boolean = false;
    disableBtn: boolean = false;
    animateBtn: boolean = false;
    touchControlState: boolean = false;
    showMenuOptions: number = 0;
    openRoomDropdown: boolean = false;
    actionDropdown: any;
    sceneRef: any;
    showRoomStatePopup: boolean = false;
    showHelpPopup: boolean = false;
    roomStateContentType: string = "";
    roomStatePopup: { [key: string]: ({ backBtn: boolean, component: ReactElement, headerText: { title: string } }) };

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => unityLayoutStore.roomData.name,
            roomName => {
                this.roomStatePopup = {
                    public: {
                        headerText: {
                            title: "Make Public",
                        },
                        backBtn: false,
                        component: <Approved
                            acceptAction={() => this.setRoomStatus(1)}
                            headerText={{
                                title: `${roomName}`,
                                subTitle: 'Do you want to make this space public?'
                            }}
                            accept={{text: 'Publish', color: "primary"}}
                            decline={{text: 'Cancel', color: "secondaryLight"}}
                            declineAction={() => this.setShowRoomStatePopup(false)}/>
                    },
                    private: {
                        headerText: {
                            title: "Make private",
                        },
                        backBtn: false,
                        component: <Approved
                            acceptAction={() => this.setRoomStatus(3)}
                            headerText={{
                                title: `${roomName}`,
                                subTitle: 'Do you want to make this space private?'
                            }}
                            accept={{text: 'Private', color: "primary"}}
                            decline={{text: 'Cancel', color: "secondaryLight"}}
                            declineAction={() => this.setShowRoomStatePopup(false)}/>
                    },
                    draft: {
                        headerText: {
                            title: "Make Draft",
                        },
                        backBtn: false,
                        component: <Approved
                            acceptAction={() => this.setRoomStatus(2)}
                            headerText={{
                                title: `${roomName}`,
                                subTitle: 'Do you want to make this space draft?'
                            }}
                            accept={{text: 'Draft', color: "primary"}}
                            decline={{text: 'Cancel', color: "secondaryLight"}}
                            declineAction={() => this.setShowRoomStatePopup(false)}/>
                    }
                };
            }
        )

        reaction(() => this.touchControlState, this.setActionDropdown)

        reaction(() => unityLayoutStore.roomData.status, this.setActionDropdown)

        this.settingOptions = [
            {
                render: () => <AnimationsSvg/>,
                showIcon: true,
                onClick: () => {
                    this.toggleOptionsVisibility(-1);
                    this.setOpenAvatarActions(true);
                },
                title: "Animations"
            },
            {
                render: () => <EmojiSvg/>,
                showIcon: true,
                onClick: () => {
                    this.toggleOptionsVisibility(1);
                },
                title: "Choose action"
            },
            {
                render: () => <ScreenshotSvg/>,
                showIcon: true,
                onClick: () => {
                    this.takeScreenShot();
                },
                title: "Screenshot"
            },
            {
                render: () => <InviteUserSvg/>,
                showIcon: (unityLayoutStore.roomData.status === 3 || unityLayoutStore.roomData.type === 6) && (isCreator() || isAdmin()) && unityLayoutStore.roomData.type !== 3,
                // showIcon: isCreator() || isAdmin(),
                onClick: () => {
                    this.toggleOptionsVisibility(-1);
                    inviteToRoomStore.setOpenInviteUserPopup(true)
                },
                title: "Invite User"
            },
            {
                render: () => <SettingSvg/>,
                showIcon: true,
                onClick: () => {
                    this.setOpenRoomDropdown(!this.openRoomDropdown);
                },
                title: "Settings"
            },
        ];
    }

    setActionDropdown = () => {
        this.actionDropdown = [{
            title: "Copy Link",
            action: () => {
                navigator.clipboard.writeText(`${window.location.origin}/room/${unityLayoutStore.roomData.id}`);
                this.actionDropdown[0].title = "Copied";
                const copyTimeout = setTimeout(() => {
                    this.actionDropdown[0].title = "Copy Link";
                    clearTimeout(copyTimeout);
                }, 2000);
            },
            showTab: true,
        }, {
            title: "Save as template",
            action: () => {
                saveAsTemplateStore.setShowSaveAsTemplatePopup(true);
                this.setOpenRoomDropdown(false);
                this.toggleOptionsVisibility(-1);
            },
            showTab: isCreator(),
        }, {
            title: "Reset all changes",
            action: () => {
                resetAllChangesStore.setShowResetAllChangesPopup(true);
                this.setOpenRoomDropdown(false);
                this.toggleOptionsVisibility(-1);
            },
            showTab: isCreator(),
        }, {
            title: "Add e-commerce",
            action: async () => {
                this.setOpenRoomDropdown(false);
                await ucraftStore.setUcraftProjectsPopup(true);
                this.toggleOptionsVisibility(-1);
            },
            showTab: isCreator() && ucraftStore.ucraftIsLogin && !ucraftStore.eCommerceConnected,
        }, {
            title: "Remove e-commerce",
            action: async () => {
                this.setOpenRoomDropdown(false);
                await ucraftStore.removeEcommerce(unityLayoutStore.roomData.id);
                this.toggleOptionsVisibility(-1);
            },
            showTab: isCreator() && ucraftStore.ucraftIsLogin && ucraftStore.eCommerceConnected,
        }, {
            title: "Make private",
            action: () => {
                this.setOpenRoomDropdown(false);
                this.setShowRoomStatePopup(true);
                this.setRoomStateContentType("private");
                this.toggleOptionsVisibility(-1);
            },
            showTab: (isCreator() || isAdmin()) && unityLayoutStore.roomData.status !== 3 && unityLayoutStore.roomData.type !== 6,
        }, {
            title: "Make draft",
            action: () => {
                this.setOpenRoomDropdown(false);
                this.setShowRoomStatePopup(true);
                this.setRoomStateContentType("draft");
                this.toggleOptionsVisibility(-1);
            },
            showTab: (isCreator() || isAdmin()) && unityLayoutStore.roomData.status !== 2 && unityLayoutStore.roomData.type !== 6,
        }, {
            title: "Make public",
            action: () => {
                this.setOpenRoomDropdown(false);
                this.setShowRoomStatePopup(true);
                this.setRoomStateContentType("public");
                this.toggleOptionsVisibility(-1);
            },
            showTab: (isCreator() || isAdmin()) && unityLayoutStore.roomData.status !== 1 && unityLayoutStore.roomData.type !== 6,
        }, {
            title: "Quality Settings",
            action: () => {
                actionpanelStore.qualitySettingsAction();
                this.setOpenRoomDropdown(false);
                this.toggleOptionsVisibility(-1);
            },
            showTab: true,
        }, {
            title: "Edit Avatar",
            action: () => {
                actionpanelStore.editAvatarAction();
                this.setOpenRoomDropdown(false);
                this.toggleOptionsVisibility(-1);
            },
            showTab: true,
        }, {
            title: "Help",
            action: () => {
                this.setOpenRoomDropdown(false);
                this.toggleOptionsVisibility(-1);
                this.openHelpPopup(true);
            },
            showTab: true,
        // }, {
        //     title: "Touch Controls",
        //     action: () => {
        //         this.setTouchControlState(!this.touchControlState);
        //         sendMessageStore.sendMessage("InputJsEventsHandle", "SetIsTouchSupported", `${this.touchControlState}`);
        //     },
        //     showTab: true,
        //     switcher: true,
        //     state: this.touchControlState
        }];
    }

    setRoomStateContentType = (str: string) => {
        this.roomStateContentType = str;
    }

    openHelpPopup = (state: boolean) => {
        this.showHelpPopup = state;
    }

    setTouchControlState = (state: boolean) => {
        this.touchControlState = state;
    }

    setShowRoomStatePopup = (state: boolean) => {
        this.showRoomStatePopup = state;
    }

    setRoomStatus = async (type: number) => {
        const response = await apiServiceV2.setRoomStatus(unityLayoutStore.roomData.id, type)
        if (!response.error) {
            unityLayoutStore.roomData.status = type
            this.setShowRoomStatePopup(false);
        }
    }

    setOpenRoomDropdown = (state: boolean) => {
        this.openRoomDropdown = state;
    }

    setOpenInviteUserPopup = (state: boolean) => {
        this.openInviteUserPopup = state;
    }

    setDisableBtn = (state: boolean) => {
        this.disableBtn = state;
    }

    setAnimateBtn = (state: boolean) => {
        this.animateBtn = state;
    }

    toggleOptionsVisibility = (action: number) => {
        this.setDisableBtn(true);
        if (!this.showMenuOptions) {
            this.animateSettingInfo(action);
        } else {
            this.setAnimateBtn(false);
            if (!!(this.showMenuOptions + action)) {
                const animationTimeout = setTimeout(() => {
                    this.animateSettingInfo(action);
                    clearTimeout(animationTimeout)
                }, 250)
            } else {
                this.toggleMenuOptionsToggle(action);
            }
        }
        this.enableBtn();
    }

    toggleMenuOptionsToggle = (action: number) => {
        this.showMenuOptions += action;
    }

    enableBtn = () => {
        const disableTimeout = setTimeout(() => {
            this.setDisableBtn(false);
            clearTimeout(disableTimeout)
        }, 250)
    }

    animateSettingInfo = (action: number) => {
        this.setAnimateBtn(true);
        this.toggleMenuOptionsToggle(action);
    }

    setInitButtons = () => {
        this.settingOptions = [
            {
                render: () => <AnimationsSvg/>,
                showIcon: true,
                onClick: () => {
                    this.toggleOptionsVisibility(-1);
                    this.setOpenAvatarActions(true);
                },
                title: "Animations"
            },
            {
                render: () => <EmojiSvg/>,
                showIcon: true,
                onClick: () => {
                    this.toggleOptionsVisibility(1);
                },
                title: "Choose action"
            },
            {
                render: () => <ScreenshotSvg/>,
                showIcon: true,
                onClick: () => {
                    this.takeScreenShot();
                },
                title: "Screenshot"
            },
            {
                render: () => <InviteUserSvg/>,
                showIcon: unityLayoutStore.roomData.status === 3 && (isCreator() || isAdmin()) && unityLayoutStore.roomData.type !== 3,
                onClick: () => {
                    this.toggleOptionsVisibility(-1);
                    inviteToRoomStore.setOpenInviteUserPopup(true)
                },
                title: "Invite User"
            },
            {
                render: () => <SettingSvg/>,
                showIcon: true,
                onClick: () => {
                    this.setOpenRoomDropdown(!this.openRoomDropdown);
                },
                title: "Settings"
            },
        ]
    }
    setEmojiesButtons = (sendEmojiType: Function) => {
        this.settingOptions = [...this.emojies.slice(0, 5).map((emoji: any) => ({
                render: () => <div className={"emojiContainer"} key={emoji.id}>
                    <img src={emoji.imgPath} alt={emoji.id}/>
                </div>,
                showIcon: true,
                onClick: () => {
                    sendEmojiType(emoji.id)
                }
            }
        )), {
            render: () => <MoreSvg/>,
            showIcon: true,
            onClick: () => {
                this.openEmojisPopup();
            },
            title: "More"
        }];
    }

    onLeaveRoom() {
        unityLayoutStore.isLoaded = false;
        unityLayoutStore.roomData = {};
        unityLayoutStore.roomUsersState = [];
        cacheService.remove("roomId");
        console.log("on Leave Room");
    }

    setOpenAvatarActions = (bool: boolean) => {
        this.openAvatarActions = bool;
    };

    openEmojisPopup = () => {
        this.emojisPopup = !this.emojisPopup;
    };

    vigogoHandler() {
        console.log("on Like Room");
    }

    setSceneRef = (ref: any)=>  {
        this.sceneRef = ref
    }

    takeScreenShot = () => {
        if (this.sceneRef.current instanceof HTMLCanvasElement) {
            this.sceneRef.current.toBlob((blob: any) => {
                this.saveBlob()(blob, `screen-${unityLayoutStore.roomData.name}.png`);
            });
        }
    };

    saveBlob = () => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        return (blob: any, fileName: any) => {
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
        };
    };


    removeState() {
    }
}

export const unityheaderStore = new Unityheader();
