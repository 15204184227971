import { makeAutoObservable } from 'mobx';
    
class SendMessage {
    sendMessage:Function
    unitySound: boolean = true;
    constructor() {
        makeAutoObservable(this);
    }

    setSendMessage = (func: Function) => {
        this.sendMessage = func
    }

    lockKeyboardInput = ()=> {
        this.sendMessage('ReactManager', 'LockKeyboardInput', 'false')
    }

    unitySoundToggle = ()=> {
        this.sendMessage("ReactManager", "EnableSound", `${!this.unitySound}`);
        this.unitySound = !this.unitySound;
    }

    unLockKeyboardInput = ()=> {
        this.sendMessage('ReactManager', 'LockKeyboardInput', 'true')
    }

    showEmotions = (str:string)=> {
        this.sendMessage(
            "ReactManager",
            "ShowEmotions",
            str
        )
    }

    changeMicById = (id:number, state:boolean)=> {
        this.sendMessage("ReactManager", "SetOtherUserMicrophoneState", JSON.stringify({
            id: id,
            mic: state,
        }))
    }
}
export const sendMessageStore = new SendMessage();
    
    