
import { makeAutoObservable } from 'mobx';
import { editProfileStore } from '../editProfile/editProfile.store';
import { editAvatarStore } from '../editAvatar/editAvatar.store';
import { globalStore } from '../../global.store';
import { myAssetsStore } from '../myAssets/myAssets.store';
import { qualityStore } from '../quality/quality.store';
import { ActionType } from './actionpanel.type';



class Actionpanel {
    actionsListStatus: boolean = false;
    actionsList: Array<ActionType> = [
        {
            id: 1,
            title: "edit profile",
            action: () => this.editProfileAction()
        },
        {
            id: 2,
            title: "edit avatar",
            action: () => this.editAvatarAction()
        },
        {
            id: 3,
            title: "my spaces",
            action: () => this.mySpacesAction()
        },
        // {
        //     id: 4,
        //     title: "my assets",
        //     action: () => this.myAssetsAction()
        // },
        {
            id: 5,
            title: "quality settings",
            action: () => this.qualitySettingsAction()
        },
        // {
        //     id: 6,
        //     title: "connect wallet",
        //     action: () => { }
        // }
    ]
    constructor() {
        makeAutoObservable(this)
    }

    setActionsListStatus = (status: boolean) => {
        this.actionsListStatus = status
    }

    editProfileAction = () => {
        editProfileStore.setShowEditProfilePopup(true)
        this.setActionsListStatus(false)
    }

    editAvatarAction = () => {
        editAvatarStore.setShowEditAvatarPopup(true)
        this.setActionsListStatus(false)
    }

    mySpacesAction = () => {
        globalStore.navigation('myspaces')
        this.setActionsListStatus(false)
    }

    myAssetsAction = () => {
        myAssetsStore.setShowMyAssetsPopup(true)
        this.setActionsListStatus(false)
    }

    qualitySettingsAction = () => {
        qualityStore.setShowQualityPopup(true)
        this.setActionsListStatus(false)
    }

    removeState() {

    }
}
export const actionpanelStore: Actionpanel = new Actionpanel();

