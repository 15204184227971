import {makeAutoObservable, reaction} from "mobx";
import { apiServiceDefault, apiServiceEcommerce, apiServiceRoom, apiServiceUser } from "./services/apiService";
import { cacheService } from "./services/cacheService";
import { globalStore } from "./global.store";
import {unityheaderStore} from "./components/unityheader/unityheader.store";

class UcraftStore {
    ucraftIsLogin: boolean;
    ucraftUrl: string = "https://sso.ucraft.ai/callback?returnUrl=";
    ucraftUserData: any = {};
    ucraftProjetcs: any = [];
    userInfoPopup: boolean = false;
    ucraftProjectsPopup: boolean = false;
    eCommerceConnected: boolean = false

    ecommerceData: { domain: string, id: string } = { domain: '', id: '' }


    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.eCommerceConnected,
            () => {
                unityheaderStore.setActionDropdown()
            }
        )
    }

    setUcraftIsLogin = (status: boolean) => {
        this.ucraftIsLogin = status
    }

    setUserInfoPopup = (status: boolean) => {
        this.userInfoPopup = status
    }

    checkUcraftToken = async (token: string) => {
        const response = await apiServiceDefault.getUcraftAccesToken(token);
        cacheService.set("ac_token", response?.accessToken);
        this.setUcraftIsLogin(true)
        await apiServiceUser.setUserUcraftToken(cacheService.get("Id") as string, { ucraft_token: response?.accessToken });
    }

    getUcraftUserAccount = async () => {
        try {
            const response = await apiServiceDefault.getUcraftUser();
            if (response.data) {
                this.ucraftUserData = response.data
                this.setUserInfoPopup(true)
            }

        } catch (e) {
            console.log(e);
        }


    }

    setUcraftProjectsPopup = async (status: boolean) => {
        if (status) await this.getUcraftProjects()
        this.ucraftProjectsPopup = status
    }

    getUcraftProjects = async () => {
        try {
            const response = await apiServiceEcommerce.getEcommerceParameters()
            if (response.success) {
                this.setUcraftProjects(response.data.projects.filter((el: any) => !el.used))
            }
        } catch (e) {
        }
    }

    setUcraftProjects = (projects: any) => {
        this.ucraftProjetcs = projects
    }

    navigateToUcrfat = () => {
        window.location.href = `${this.ucraftUrl}${window.location.href}/&target=blank`
    }

    setEcommerceData = (domain: string, id: string) => {
        this.ecommerceData.domain = domain;
        this.ecommerceData.id = id
    }

    disconnectUcraft = () => {
        apiServiceUser.setUserUcraftToken(cacheService.get("Id") as string, { ucraft_token: "" });
        this.setUcraftIsLogin(false)
        apiServiceDefault.setLogoutUcraft()
        cacheService.remove('ac_token')
        this.ecommerceData = { domain: '', id: '' }
        this.setUcraftProjects([])
        this.setEcommerceConnected(false)
    }

    setEcommerceConnected = (status: boolean) => {
        this.eCommerceConnected = status
    }

    removeEcommerce = async (id: string) => {
        const response = await apiServiceRoom.setEcommerceForRoom(id, null, null)
        if (response.success) {
            this.setEcommerceConnected(false)
            globalStore.setToasts(true, 'remove_ucraft_project')

        }
    }

    addEcommerce = async (id: string, domain: string | null, e_id: string | null) => {
        const response = await apiServiceRoom.setEcommerceForRoom(id, domain, e_id)
        if (response.success) {
            this.setEcommerceConnected(true)
            this.setUcraftProjectsPopup(false)
            this.setEcommerceData(response.data.ecommerce_domain as string, response.data.ecommerce_id as string)
            globalStore.setToasts(true, 'added_ucraft_project')
        }
    }

}

export const ucraftStore: UcraftStore = new UcraftStore() 