import {makeAutoObservable, runInAction} from "mobx";
import {apiServiceBase} from "../../services/apiService";

class MyAssetsStore {
    showMyAssetsPopup: boolean = false;
    assets: any[] = [];
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this)
    }

    setShowMyAssetsPopup = (state: boolean) => {
        this.showMyAssetsPopup = state;
    }

    getAssets = async () => {
        this.assets = [];
        const {data, success} = await apiServiceBase.getGiftList();
        const {data: vigogoData, success: successVigogo} = await apiServiceBase.getVigogoGiftList();
        if (success && successVigogo) {
            runInAction(() => {
                const vigogoDataList = vigogoData.map((item: any) => {
                    return {
                        "nft_img_url": item.image,
                        "nft_url": "https://exchange.fastex.com/assets/voucher",
                        "nft_name": item.info,
                        "nft_description": item.title,
                        "created_at": item.created_at,
                        "type": "FTN"
                    }
                });
                const nftDataList = data.map((item: any) => {
                    return {
                        ...item, item,
                        "type": "NFT"
                    }
                });
                this.assets = [...nftDataList, ...vigogoDataList].sort((a, b) => +(new Date(b.created_at)) - +(new Date(a.created_at)));
                this.loading = false;
            })
        } else {
            this.loading = false;
        }
    }
    clearAssets = () => {
        this.loading = true;
        this.assets = [];
    }
}

export const myAssetsStore = new MyAssetsStore()