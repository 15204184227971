import React from "react";
import { routesByProject } from "../types/global.types";
import TermsAndConditions from "../components/policies/termsAndConditions";
import PrivacyPolicy from "../components/policies/privacyPolicy";

const Dashboard = React.lazy(() => import("../layout/dashboard"));
const Cerebrum = React.lazy(() => import("../modules/cerebrum"));
const Community = React.lazy(() => import("../modules/community"));
const Copia = React.lazy(() => import("../modules/copia"));
const Calendarium = React.lazy(() => import("../modules/calendarium"));
// const Games = React.lazy(() => import("../modules/games"));
const UnityLayout = React.lazy(() => import("../modules/unityLayout"));
const Myspaces = React.lazy(() => import("../modules/myspaces"));

export const ProjectsRoutes: routesByProject = {
    95942005: [
        {
            path: '',
            component: Dashboard,
            id: 1,
            child: [
                {
                    path: "",
                    component: Cerebrum,
                    id: 1,
                },
                {
                    path: "community",
                    component: Community,
                    id: 2
                },
                {
                    path: "copia",
                    component: Copia,
                    id: 3,
                },
                {
                    path: "calendarium",
                    component: Calendarium,
                    id: 4
                },
                // {
                //     path: "forsale",
                //     component: Forsale,
                //     id: 5
                // },
                // {
                //     path: "games",
                //     component: Games,
                //     id: 6
                // },
                {
                    path: "myspaces",
                    component: Myspaces,
                    id: 7
                },
                {
                    path: "terms",
                    component: TermsAndConditions,
                    id: 8
                },
                {
                    path: "privacy",
                    component: PrivacyPolicy,
                    id: 9
                }
            ]
        },
        {
            path: 'room/:id',
            component: UnityLayout,
            id: 2
        },
    ]

}