
import {makeAutoObservable} from 'mobx';
import { apiServiceV2 } from '../../services/apiService';
import { getFileSize } from '../../helpers/utils';
import { TranslatableError } from '../../modules/scenetemplate/hooks/constants';
import { globalStore } from '../../global.store';
import { cardsblockStore } from '../cardsblock/cardsblock.store';


class TicketUpload {
    roomId:number=0;
    visitors:any[]=[];
    eventMember:boolean=false;
    disabled:boolean=false;
    eventMinutes:Date;
    errorMsg:any = {
        file: "",
        number: "",
    };
    ticketNumberValue:string = '';
    eventData:any= false;
    progress:number = 0;
    reader:FileReader = new FileReader();
    file:File | null = null;
    fileOptions:any = {
        name: '',
        size: 0
    }
    constructor() {
        makeAutoObservable(this);
    }

    get hasTicketFile(){
        return !!this.file;
    }

    get hasTicketNumber(){
        return !!this.ticketNumberValue;
    }

    onInputTicketNumber = (val:any)=> {
        this.errorMsg = {
            file: "",
            number: ""
        }
        this.ticketNumberValue = val
    }

    onDropTicket = (files:File[])=> {
        const file = files[0];
        this.reader.readAsDataURL(file);
        const fileSize = getFileSize(file.size);
        if(fileSize > 2 ){
            this.file = null;
            this.errorMsg = {
                file: "",
                number: "file size cannot exceed 2 MB"
            }
            return null
        }
        this.fileOptions = {
            name: file.name,
            size: fileSize
        }
        this.reader.onprogress = (data:any) => {
            if (data.lengthComputable) {
                this.progress = 100;
                this.errorMsg = {
                    file: "",
                    number: ""
                }
            }
        };
        
        this.reader.onloadend = (data) => {
            if (data.lengthComputable) {
                const formData = new FormData();
                this.file = file;
                formData.append("qr", file);
                this.sendTicket(formData, this.roomId)
            }
        };

        this.reader.onerror = (error) => {
            console.log("Error: ", error);
        };
    }

    sendTicketNumber = () => {
        const formData = new FormData();
        this.disabled = true;
        formData.append("ticketNumber", this.ticketNumberValue);
        this.sendTicket(formData, this.roomId)
    }

    sendTicket = async (data:any, id: number)=> {
        try {
            const res = await apiServiceV2.setUploadTicket(data, id);

            this.disabled = false;
            if(!res.error){
                // this.eventMember = true;
                cardsblockStore.setRoomEventMember(this.roomId);
            }else{
                throw res.reasons ? res.reasons : res;
            }

        } catch (error) {
            const typedError = error as TranslatableError;
            if (typedError.message) {
                this.errorMsg = typedError.message;
                this.errorMsg = {
                    file: this.file && typedError.message,
                    number: this.ticketNumberValue && typedError.message
                };
            } else {
                this.errorMsg = {
                    file: "",
                    number: 'Invalid Ticket Number'
                };
            }
        }
    }

    onRemoveTicketFile = () => {
        this.file = null;
        this.progress = 0;
        this.fileOptions = {
            name: '',
            size: 0
        };
        this.errorMsg = {
            ...this.errorMsg,
            file: ""
        }
    }

    uploadTicketPopup = (eventData:any, roomId:number = 0, visitors: any[] = []) => {
        if(eventData){
            this.roomId = roomId;
            this.visitors = visitors;
            this.eventData = {
                ...eventData,
                eventProperty: Array.isArray(eventData.eventProperty) ? eventData.eventProperty[0] : eventData.eventProperty,
            };
            this.eventMinutes = eventData.eventStartDate as Date;
        }else{
            this.removeState();
        }
    }

    ticketInfoHandler = (min:string)=> {
        if(min) {
            this.uploadTicketPopup(false);
            if(window.location.pathname === `/room/${this.roomId}`) {
                globalStore.navigation(`/`);
            }
        } else {
            globalStore.navigation(`/room/${this.roomId}`);
            globalStore.navigation(0);
        }
        this.removeState();
    }

    removeState = ()=> {
        this.onRemoveTicketFile();
        this.ticketNumberValue = '';
        this.errorMsg = {
            file: "",
            number: ""
        }
        this.eventData = false;
        this.eventMember = false;
    }
}
export const ticketupload = new TicketUpload();

