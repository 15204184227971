import { IAgoraRTCRemoteUser, ICameraVideoTrack, ILocalAudioTrack, ILocalVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import { makeAutoObservable } from 'mobx';
import * as Agora  from "../hooks/constants";
import { cacheService } from '../../../services/cacheService';
    
    const {enabled, notFound, disabled}= Agora.MEDIA_DEVICE_STATUS
    class Agoratemplate {
        toggleScreen:boolean = false;
        videoTracks:any={};
        enableSound:string=disabled;
        enableVideo:string=disabled;
        micLoading:boolean=false;
        videoLoading:boolean=false;
        screenTrack:any={};
        remoteUsersVideo:IAgoraRTCRemoteUser[]=[];
        remoteUsersAudio:IAgoraRTCRemoteUser[]=[];
        remoteUsersScreen:IAgoraRTCRemoteUser[]=[];
        permissionAudio:any = [];
        localAudioTrack:IMicrophoneAudioTrack | undefined=undefined;
        localVideoTrack:ILocalVideoTrack | undefined=undefined;
        localScreenTrack:ILocalVideoTrack | undefined=undefined;
        constructor() {
            makeAutoObservable(this);
        }

        get micEnabled(){
            return this.enableSound === enabled;
        }

        get videoEnabled(){
            return this.enableVideo === enabled;
        }

        setVideoTracks = (id:any, videoTrack:any) => {
            this.videoTracks = {...this.videoTracks, [id]: videoTrack};
        }

        setScreenTracks = (screenTrack:ILocalVideoTrack) => {
            this.videoTracks = screenTrack;
        }

        setMicStatus = (status:string) => {
            this.enableSound = status;
        }

        setIsLoadingMic = (flag:boolean)=> {
            this.micLoading = flag;
        }

        setVideoStatus = (status:string) => {
            this.enableVideo = status;
        }

        setIsLoadingVideo = (flag:boolean)=> {
            this.videoLoading = flag;
        }

        setLocalAudioTrack = (track:IMicrophoneAudioTrack | undefined)=> {
            this.localAudioTrack = track;
        }
        setLocalVideoTrack = (track:ICameraVideoTrack | undefined)=> {
            const videoTextureId = `${Math.floor(cacheService.getMyId / 2)}`;
            this.setVideoTracks(videoTextureId, track)
            this.localVideoTrack = track;
        }

        setLocalScreenTrack = (track:ILocalVideoTrack)=> {
            const videoTextureId = `${Math.floor(cacheService.getMyId / 3)}`;
            this.localScreenTrack = track;
            this.setVideoTracks(videoTextureId, track)
        }
        
        setRemoteUsersVideo = (stream:IAgoraRTCRemoteUser[])=> {
            this.remoteUsersVideo = stream;
        }
        setRemoteUsersAudio = (stream:IAgoraRTCRemoteUser[])=> {
            this.remoteUsersAudio = stream;
        }
        setRemoteUsersScreen = (stream:IAgoraRTCRemoteUser[])=> {
            this.remoteUsersScreen = stream;
        }

        setSpeakerList(arr:[]) {
            
        }
    
        setScreenShare = (bool:boolean) => {
            this.toggleScreen = bool;
        }

        getVideos = ()=> {
            return this.videoTracks;
        }
    }
    export const agoraStore = new Agoratemplate();
    
    