import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import avatar1 from "../../assets/images/avatars/avatar-1.png";
import avatar2 from "../../assets/images/avatars/avatar-2.png";
import {editAvatarStore} from "./editAvatar.store";
import Button from "../../ui/button";
import Modalcontainer from "../modalcontainer";
import "../fulgur/styles.scss"

const EditAvatar = observer(() => {
    const {setShowEditAvatarPopup, setAvatarEditor} = editAvatarStore;
    const [editAvatarState, setEditAvatarState] = useState("");

    const chooseEditAvatarState = (e: any) => {
        setEditAvatarState(e.target.value);
    }

    const closePopup = () => {
        setShowEditAvatarPopup(false);
    }

    return (
        <Modalcontainer backBtn={false} closePopup={closePopup} headerText={{
            title: "Avatar time!",
            subTitle: "Choose the avatar system that suits you best."
        }} minimized={true}>
            <div className="avatars">
                <label className="avatar">
                    <input value={"rpm"} onClick={chooseEditAvatarState} name="avatarRadio" className="avatarRadio"
                           type="radio"/>
                    <span className="avatarImageHolder">
                                    <img className="avatarImage" src={avatar1} alt="Avatar 1"/>
                                </span>
                    <span className="avatarCheckmark"/>
                </label>
                <label className="avatar">
                    <input value={"avaturn"} onClick={chooseEditAvatarState} name="avatarRadio" className="avatarRadio"
                           type="radio"/>
                    <span className="avatarImageHolder">
                                    <img className="avatarImage" src={avatar2} alt="Avatar 2"/>
                                </span>
                    <span className="avatarCheckmark"/>
                </label>
            </div>
            <Button onClick={() => {
                setAvatarEditor(editAvatarState);
                closePopup();
            }} type="rounded-30" size='lg' color='primary' disabled={!editAvatarState}>
                <span className="buttonTxt">Continue</span>
            </Button>
        </Modalcontainer>
)
})

export default EditAvatar;