import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from "mobx-react-lite";

const ScrollToTop = observer(() => {
    const {pathname} = useLocation();
    // const oldPage = useRef(pathname);

    useEffect(() => {
        // if (pathname !== oldPage.current || !oldPage.current) {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        } catch (error) {
            window.scrollTo(0, 0)
        }
        // oldPage.current = pathname
        // }
    }, [pathname]);

    return null;
})

export default ScrollToTop;