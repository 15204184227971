import "./styles.scss"
import Button from "../../../../ui/button"
import {ApprovedProps} from "../../modalcontainer.type";

export const Approved: React.FC<ApprovedProps> = ({ headerText, accept, decline, declineAction, acceptAction }) => {
    return (
        <div className="deleteSpace" >
            <div className="deleteSpaceInfoRow">
                <p className="deleteSpaceTitle">
                    {headerText?.title}
                </p>
                <p className="deleteSpaceSubTitle">{headerText?.subTitle}</p>
            </div>
            <div className="renameSpaceInfoActions">
                <div className="profileInfoAction">
                    <Button type="rounded-30" size='w100 lg' color={decline.color} onClick={() => declineAction(false)}>
                        <span className="buttonTxt">{decline.text}</span>
                    </Button>
                </div>
                <div className="profileInfoAction">
                    <Button type="rounded-30" size='w100 lg' color={accept.color} onClick={acceptAction} disabled={accept.disabled}>
                        <span className="buttonTxt">{accept.text}</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}
