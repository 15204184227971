import {makeAutoObservable, runInAction} from 'mobx';
import {InputType, StepType, authSectionTypes, formsType, sectionTypes} from './authorization.type';
import {apiServiceV2} from '../../services/apiService';
import {cacheService} from '../../services/cacheService';
import {format} from 'date-fns';
import {CustomAxiosResponse} from '../../types/global.types';
import {globalStore} from '../../global.store';
import {AuthForm} from '../../helpers/authorization_form';
import {editAvatarStore} from "../../components/editAvatar/editAvatar.store";


export class Authorization {
    redirectUrl: string = "";
    userData: any = {};
    clearSearchParams: boolean = false;
    steps: { [key in sectionTypes]: number } = {
        login: 1,
        registration: 1,
        forgot: 1
    }
    typeofSection: sectionTypes = "login"
    forms: formsType = {
        login: {},
        registration: {},
        forgot: {}
    }
    authSections: authSectionTypes = AuthForm
    sectionContent: StepType
    openUnverifiedPopup: boolean = false

    constructor() {
        makeAutoObservable(this);
    }

    setOpenUnverifiedPopup = (state: boolean) => {
        this.openUnverifiedPopup = state;
    }

    setSectionContent = (type: sectionTypes): void => {
        this.resetSection()
        this.typeofSection = type;
        this.sectionContent = this.authSections[type][this.steps[this.typeofSection]];
    }

    continue = (): void => {
        this.steps[this.typeofSection] += 1
        this.setSectionContent(this.typeofSection)
    }

    goToVerification = (): void => {
        this.steps["login"] = 1
        this.steps["registration"] = 2
        this.setSectionContent("registration")
    }

    back = (type: number): void => {
        this.steps[this.typeofSection] -= type
        this.setSectionContent(this.typeofSection)
    }

    loginWallet = async(token:string)=> {
        cacheService.clear();
            cacheService.set("auth_token", token);
            globalStore.setIsLogin(true)
            await globalStore.projectConfiguration();
        this.clearSearchParams = true;
    }

    logIn = async (data: any, fromReg = false): Promise<boolean | string> => {
        let response: CustomAxiosResponse = await apiServiceV2.login(data ? data : this.forms.login as keyof typeof apiServiceV2.login);
        if (response.error) {
            runInAction(() => {
                if(!!response.data?.is_email_confirmed) {
                    const pass = data?.password || this.forms.login.password;
                    this.setOpenUnverifiedPopup(true)
                    this.forms.registration.email = response.data.email
                    this.forms.registration.userId = response.data.id
                    this.forms.registration.password = pass
                } else {
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = true
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = "Your email or password is incorrect. Please check and try again"
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true
                }
            })
            return false
        } else {
            cacheService.clear();
            cacheService.set("auth_token", response.data.accessToken.token);
            globalStore.setIsLogin(true)
            this.steps = {
                login: 1,
                registration: 1,
                forgot: 1
            }
            await globalStore.projectConfiguration();
            if(fromReg) {
                editAvatarStore.setShowEditAvatarPopup(true)
                this.clearSearchParams = true;
            }
            return 'delete'
        }
    }

    registration = async (): Promise<boolean | void> => {
        const registerForm = {
            username: this.forms.registration.name,
            email: this.forms.registration.email,
            password: this.forms.registration.password,
            gender: Number(this.forms.registration.gender),
            birthday: format(this.forms.registration.date as string, "yyyy-MM-dd")
        }
        let response: CustomAxiosResponse = await apiServiceV2.registration(registerForm)
        if (response.error as Boolean) {
            runInAction(() => {
                if(!!response?.reasons?.messages) {
                    Object.keys(response?.reasons?.messages).forEach((reason: any) => {
                        this.setLoginForm(reason, this.forms.registration[reason] as string, false)
                        const getLabel = this.authSections[this.typeofSection][this.steps[this.typeofSection]].inputs.filter((el: any) => {
                            return (el.name === "name" && reason === "username") || el.name === reason
                        })[0];
                        if(getLabel) {
                            getLabel.validation.error = response?.reasons?.messages[reason];
                            getLabel.validation.status = false;
                        }
                    })
                } else {
                    // const errorMessage = response?.reasons?.messages?.email ? "The email is already registered" : Object.values(response?.reasons?.messages)[0] as string;
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = response.error;
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = response?.reasons?.messages as string;
                    this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true;
                }
            })
        } else {
            this.forms.registration.userId = response.data.publicId;
            this.authSections['registration'][2]!.subtitle!.navigation!.text = this.forms.registration.email as string;
            this.continue()
        }
        return false
    }

    verifyEmail = async (): Promise<string | void> => {
        const response: CustomAxiosResponse = await apiServiceV2.setRegistrationVerifyCode(this.forms.registration.userId as string, this.forms.registration.code as string)
        if (response.error) {
            runInAction(() => {
                this.setLoginForm("code", this.forms.registration["code"] as string, false)
                const getLabel = this.authSections[this.typeofSection][this.steps[this.typeofSection]].inputs.filter((el: any) => {
                    return el.name === "code"
                })[0];
                if(getLabel) {
                    getLabel.validation.error = "Incorrect code!";
                    getLabel.validation.status = false;
                }
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true

            })
        } else return (async () => {
            await this.logIn({
                email: this.forms.registration.email,
                password: this.forms.registration.password,
            }, true)
        })()

    }

    checkForgotEmail = async (): Promise<void> => {
        const response: CustomAxiosResponse = await apiServiceV2.setForgotEmail({email: this.forms.forgot.email as string})
        if (response.error) {
            runInAction(() => {
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = true
                // this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = response?.message
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = "The email is not registered"
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true
            })
        } else {
            this.forms.forgot.userId = response.data.publicId;
            this.authSections['forgot'][2]!.subtitle!.navigation!.text = this.forms.forgot.email as string;
            this.continue()
        }
    }

    resendForgotCode = (): void => {
        apiServiceV2.setForgotEmail({email: this.forms.forgot.email as string})
    }

    resendVerifyCode = (): void => {
        apiServiceV2.setForgotEmail({email: this.forms.registration.email as string})
    }

    checkForgotCode = async (): Promise<void> => {
        const response: CustomAxiosResponse = await apiServiceV2.setForgotCode({
            email: this.forms.forgot.email as string,
            code: this.forms.forgot.code as string
        })
        if (response.error) {
            runInAction(() => {
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = true
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = response?.reasons?.messages?.code
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true
            })
        } else {
            this.continue()
        }

    }

    createNewPass = async (): Promise<boolean | string | void> => {
        const response: CustomAxiosResponse = await apiServiceV2.setNewPassword(
            {
                email: this.forms.forgot.email as string,
                newPasswordOne: this.forms.forgot.password as string,
                newPasswordTwo: this.forms.forgot.confpassword as string,
            }
        )
        if (response.error) {
            runInAction(() => {
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = true
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.message = response?.errors?.detail
                this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true
            })
            return false
        } else return (async () => {
            await this.logIn({
                email: this.forms.forgot.email,
                password: this.forms.forgot.password
            })
        })()
    }

    setLoginForm = (name: string, value: string, valid: boolean, errorMessage: string  = "") => {
        this.forms[this.typeofSection][name] = value
        this.authSections[this.typeofSection][this.steps[this.typeofSection]]["inputs"] = this.authSections[this.typeofSection][this.steps[this.typeofSection]]["inputs"].map((input: InputType) => {
            if (input.name === "confpassword" && name === "password") input.validation.status = (value === this.forms[this.typeofSection]["confpassword"])
            else if (input.name === name) {
                input.validation.status = valid
                input.validation.error = errorMessage
            }
            return input
        })
        this.authSections[this.typeofSection][this.steps[this.typeofSection]]["button"]["disabled"] = false
        this.authSections[this.typeofSection][this.steps[this.typeofSection]]["inputs"].forEach((input: InputType) => {
            if (!input.validation.status) this.authSections[this.typeofSection][this.steps[this.typeofSection]]["button"]["disabled"] = true
        });
        this.authSections[this.typeofSection][this.steps[this.typeofSection]].error.status = false
    }

    resetSection = (): void => {
        this.authSections[this.typeofSection][this.steps[this.typeofSection]].button.disabled = true
        this.authSections[this.typeofSection][this.steps[this.typeofSection]].error = {
            status: false,
            message: ''
        }
        this.authSections[this.typeofSection][this.steps[this.typeofSection]]["inputs"].forEach((input: InputType) => {
            input.validation.status = null
        })
    }

    clearAuthData = () => {
        this.userData = {};
    }
}

export const authorizationStore: Authorization = new Authorization();

