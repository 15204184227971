import {makeAutoObservable} from 'mobx';
import {isValidEmail} from "../../../../helpers/utils";
import {apiServiceV2} from "../../../../services/apiService";
import React from "react";


class InviteToRoomStore {
    openInviteUserPopup: boolean = false;
    emailList: any = [];
    loading: boolean = false;
    res: string = "";
    currentEmail: string = "";

    constructor() {
        makeAutoObservable(this)
    }


    setOpenInviteUserPopup = (state: boolean) => {
        this.openInviteUserPopup = state;
    }

    setEmailList = (arr: any) => {
        this.emailList = arr;
    }

    setLoading = (state: boolean) => {
        this.loading = state;
    }

    setRes = (str: string) => {
        this.res = str;
    }

    setCurrentEmail = (email: string) => {
        this.currentEmail = email;
    }

    setInviteUser = (users: any[]) => apiServiceV2.setInviteUser(users);

    handleInvite = async () => {
        try {
            if (!this.emailList.length) return;
            this.setLoading(true);
            const users = this.emailList.map((email: any) => email.value).filter((el: string) => isValidEmail(el));
            const response = await this.setInviteUser(users);
            if(!response.error) {
                this.setRes("resolve");
                this.setLoading(false);
            } else {
                this.setRes("reject")
                this.setLoading(false);
            }
        } catch (e: any) {
            console.warn("ERROR: ", e.message);
        }
    };

    createElements = (val: string) => {
        const arr = val.split(/\s+/);
        this.setEmailList([...this.emailList, ...arr.filter((arrEl: string) => arrEl.length).map((el: string) => (
            {
                value: el,
                isValid: Boolean(isValidEmail(el))
            }
        ))])
        this.setCurrentEmail("");
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setCurrentEmail(e.target.value);
        // @ts-ignore
        if (e.nativeEvent.data === " ") {
            this.createElements(e.target.value);
        }
    };

    pasteEmails = (e: React.ClipboardEvent) => {
        let clipboardData, pastedData;
        e.stopPropagation();
        e.preventDefault();
        clipboardData = e.clipboardData;
        pastedData = clipboardData.getData('Text');
        this.createElements(pastedData);
    };

    removeUser = (currentIndex: number) => {
        this.setEmailList([...this.emailList.slice(0, currentIndex), ...this.emailList.slice(currentIndex + 1)])
    };

    correctEl = (val: string, currentIndex: string) => {
        this.setEmailList(this.emailList.map((el: any, ind: number) => {
            if (+currentIndex === ind) {
                return {
                    value: val,
                    isValid: Boolean(isValidEmail(val))
                }
            }
            return el;
        }))
    }

    removeState() {

    }
}

export const inviteToRoomStore = new InviteToRoomStore();

