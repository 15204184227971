import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import "../style.scss";
import {editAvatarStore} from "../../editAvatar/editAvatar.store";

const AvatarContent = observer(({ setToggleChange }: { setToggleChange: Function }) => {
    const {setUserAvatar} = editAvatarStore;
    const [count, setCount] = useState(true);
    const iFrameRef = useRef<any>(null);

    const isEmpty = (str: string) => {
        return (!str || str.length === 0);
    };

    useEffect(() => {
        const iFrame = iFrameRef.current;

        if (iFrame) {
            iFrame.src = `https://fastexverse.readyplayer.me/avatar?clearCache&frameApi`;
        }
    }, []);

    function subscribe(event: any) {
        const json = parse(event);

        if (json?.source !== 'readyplayerme') {
            return;
        }
        if (json.eventName === 'v1.frame.ready') {
            let iFrame = iFrameRef.current;
            if (iFrame && iFrame.contentWindow) {
                iFrame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**',
                    }),
                    '*',
                );
            }
        }
        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
            if (!isEmpty(json.data.url) && count) {
                try {
                    void setUserAvatar(json.data.url);
                    setCount(false);
                } catch (e: any) {
                    console.warn('ERROR: ', e.message);
                }
            }
            setToggleChange();
        }
    }

    function parse(event: any) {
        try {
            return JSON.parse(event.data);
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);
        return () => {
            window.removeEventListener('message', subscribe);
            document.removeEventListener('message', subscribe);
        };
    });

    return (
        <iframe
            allow="camera *"
            className="iFrame"
            id="avatar-container"
            ref={iFrameRef}
            title="Ready Player Me"
        />
    );
});

export default AvatarContent;

