import { useEffect, useState } from 'react'

const useImage = (path: string): { [key: string]: string } => {
    const [image, setImage] = useState<string>("")

    useEffect(() => {
        (async () => {
            try {
                const response = await import(`../assets/images/${path}`)
                setImage(response.default)
            } catch (err) {
                throw (err)
            }
        })()
    }, [path])

    return {
        image,
    }
}

export default useImage