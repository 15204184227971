export const ToastsContent: any = {
    inprivatcall: {
        type: "error",
        content: "This user is already in a private call."
    },
    freeze: {
        type: "error",
        content: "Your mic and camera are off by the space admin."
    },
    added_ucraft_project: {
        type: "success",
        content: "Your shop has successfully been connected."
    },
    remove_ucraft_project: {
        type: "success",
        content: "Your shop has successfully been disconnected."
    },
    upload_image: {
        type: "success",
        content: "Well done, your image has been successfully added!"
    },
    upload_video: {
        type: "success",
        content: "Well done, your video has been successfully added!"
    },
    upload_model: {
        type: "success",
        content: "Well done, your model has been successfully added!"
    },
    upload_pdf: {
        type: "success",
        content: "Well done, your pdf has been successfully added!"
    },
    upload_ecommerce: {
        type: "success",
        content: "Your shop Successfully Added!"
    },
    upload_portal: {
        type: "success",
        content: "Well done, portal has been successfully added!"
    },
    added_hoory_token: {
        type: "success",
        content: "Well done, hoory token has been successfully added!"
    },
    its_success: {
        type: "success",
        content: "It's a success!"
    },
    something_went_wrong: {
        type: "error",
        content: "Something went wrong!"
    },
    microphone_permission: {
        type: "error",
        content: "Microphone Permission Denied!"
    },
    username_exist: {
        type: "error",
        content: "The Username is already taken!"
    },
    duble_login : {
        type: "error",
        content: "You can use one account to log in from one device only at a time."
    },
    private_event : {
        type: "error",
        content: "Mic and camera are off during the event."
    }
}
