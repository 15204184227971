import {makeAutoObservable} from "mobx";
import {apiServiceV2} from "../../services/apiService";
import {globalStore} from "../../global.store";
import {sendMessageStore} from "../../modules/unityLayout/sendMessage.store";

class EditAvatarStore {
    showEditAvatarPopup: boolean = false;
    avatarEditor: string = ""

    constructor() {
        makeAutoObservable(this)
    }

    setShowEditAvatarPopup = (state: boolean) => {
        this.showEditAvatarPopup = state;
    }

    setAvatarEditor = (state: string) => {
        this.avatarEditor = state;
    }

    setUserAvatar = async (avatarUrl: string) => {
        const response =await apiServiceV2.setUserAvatar({
            'glb': avatarUrl,
        })
        if(!response.error) {
            const res = await apiServiceV2.getCurrentUser();
            if(!res.error) {
                globalStore.userData.readyPlayerMeAvatar = res.data.readyPlayerMeAvatar;
                globalStore.userData.readyPlayerMeAvatarImage = res.data.readyPlayerMeAvatarImage;
                sendMessageStore.sendMessage && sendMessageStore.sendMessage("ReactManager", "AvatarUrlChanged", res.data.readyPlayerMeAvatar);
            }
        }
    };

    endAvaturnSession = async (data: {
        url: string,
        avatarId: string | number
    }) => {
        const response = await apiServiceV2.endAvaturnSession({
            'avatar_url': data.url,
            "avatar_id": data.avatarId,
            "user_id": globalStore.userData.id
        })
        if(!response.error) {
            const res = await apiServiceV2.getCurrentUser();
            if(!res.error) {
                globalStore.userData.readyPlayerMeAvatar = res.data.readyPlayerMeAvatar;
                globalStore.userData.readyPlayerMeAvatarImage = res.data.readyPlayerMeAvatarImage;

                sendMessageStore.sendMessage && sendMessageStore.sendMessage("ReactManager", "AvatarUrlChanged", res.data.readyPlayerMeAvatar);
            }
        }
    };
}

export const editAvatarStore = new EditAvatarStore()