import { makeAutoObservable } from "mobx";
import { cacheService } from "../../services/cacheService";

export type TShopItem = {
    "ucraftId": number,
    "name": string,
    "picture": string,
    "price": string,
    "category": string,
    "currency": string,
    "sku": string,
    "url_path": null,
    "position": {
        "position_x": number,
        "position_y": number,
        "position_z": number,
        "rotation_x": number,
        "rotation_y": number,
        "rotation_z": number,
        "size": number
    },
    "Transform": {
        "positionX": number,
        "positionY": number,
        "positionZ": number,
        "rotationX": number,
        "rotationY": number,
        "rotationZ": number,
        "size": number
    },
    "EcommerceDomain": null
}

const productsUrl:any = {
    57: (category:string, sku:string)=>`https://arleam.ucraft.ai/${category}/p/${sku}`,
    99: (category:string, sku:string)=>`https://winedoor.ucraft.ai/${category}/p/${sku}`,
}


class ShopEcommerce {
    showShop:boolean=false
    shopData:any ={}
    iFrameUrl: string = ''
    constructor() {
        makeAutoObservable(this);
    }

    setShopPopup = (bool:boolean)=> {
        this.showShop = bool;
    }

    setShopData = (data:TShopItem)=> {
        const roomID = Number(cacheService.get("roomId"))
        this.shopData = data;
        this.iFrameUrl = productsUrl[roomID](data.category, data.sku)
    }

    removeState() {
    }
}
export const shopEcommerceStore = new ShopEcommerce()
