import {makeAutoObservable} from 'mobx';
import { apiServiceV2} from "../../../../services/apiService";
import {unityLayoutStore} from "../../../../modules/unityLayout/unityLayout.store";
import {globalStore} from "../../../../global.store";

class SaveAsTemplateStore {
    templateName: string = "";
    showSaveAsTemplatePopup: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    setTemplateName = (name: string): void => {
        this.templateName = name;
    }

    setShowSaveAsTemplatePopup = (state: boolean): void => {
        this.showSaveAsTemplatePopup = state;
    }

    saveTemplate = async () => {
        // const res = await apiServiceRoom.saveTemplate({
        //     public_id: cacheService.get("RoomId"),
        //     template_name: value
        // });
        const res = await apiServiceV2.saveTemplate(unityLayoutStore.roomData.id);
        if (!res.error) {
            globalStore.setToasts(true, 'its_success');
        } else {
            globalStore.setToasts(true, 'something_went_wrong');
        }
        this.setShowSaveAsTemplatePopup(false);
    }
}

export const saveAsTemplateStore = new SaveAsTemplateStore();

