import { observer } from "mobx-react-lite"

import './styles.scss'
import { globalStore } from "../../global.store"
import { ToastsContent } from "../../helpers/toasts_content"
import { ImgImporter } from "../../ui/imgImporter"

const Toast = observer(() => {
    const { toastType, toastStatus } = globalStore
    return (
        <div className={`toastContainer ${toastStatus && 'active'}`}>

            <div className={`toast_popup ${ToastsContent?.[toastType as string]?.type}`}>
                <div className='popup_icon'>
                    <ImgImporter path={`toasts/${ToastsContent?.[toastType as string]?.type}.svg`}/>
                </div>
                <div className='modal_text'>{ToastsContent?.[toastType as string]?.content}</div>
            </div>

        </div>
    )
})

export default Toast