import "./styles.scss"
import Button from "../../../../ui/button"
import {NotificatonProps} from "../../modalcontainer.type"
import {useEffect, useRef, useCallback, useState} from "react";

export const Notification: React.FC<NotificatonProps> = ({headerText, button, action, time = null}) => {
    const timerRef = useRef<any>(null);
    const [timer, setTimer] = useState<any>({});

    const timeObjectToSeconds = useCallback(({hours = 0, minutes = 0, seconds = 0}) => {
        return hours * 3600 + minutes * 60 + seconds || 0
    }, [time]);

    const secondsToTimeObject = (totalSeconds: any) => {
        if (totalSeconds <= 0) return
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return {hours, minutes, seconds};
    };

    let totalSeconds = timeObjectToSeconds(time || {})

    let timerHandler = () => {
        if (totalSeconds > 1) {
            setTimer(secondsToTimeObject(--totalSeconds));
        } else {
            clearInterval(timerRef.current);
            // @ts-ignore
            action();
        }
    }

    useEffect(() => {
        if (time) {
            timerRef.current = setInterval(timerHandler, 1000);

            return () => {
                clearInterval(timerRef.current);
            }
        }
    }, [time])

    return (
        <div className="deleteSpace">
            <div className="deleteSpaceInfoRow">
                <p className="deleteSpaceTitle">
                    {headerText?.title}
                </p>
                <p className="deleteSpaceSubTitle">{headerText?.subTitle} {timer ?
                    Object.keys(timer).map((item: any, i) => {
                        if (timer[item] > 0) return <span
                            key={i}>{` ${timer[item]} ${item}`}</span>

                    })
                    : ""}</p>
            </div>
            <div className="renameSpaceInfoActions">
                <div className="profileInfoAction">
                    <Button type="rounded-30" size='w100 lg' color={button.color} onClick={action as Function}>
                        <span className="buttonTxt">{button.text}</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}
