import {observer} from "mobx-react-lite";
import {ModalcontainerView} from '../modalcontainerView/modalcontainer.view'
import {ModalContainerProps} from "../modalcontainer.type";

export const ModalcontainerController: React.FC<ModalContainerProps> = observer((
    {
        className = '',
        backBtn,
        children,
        closePopup,
        headerText,
        backBtnInfo,
        minimized,
        canClose
    }): JSX.Element => {
    return (
        <ModalcontainerView className={className} backBtn={backBtn} closePopup={closePopup} headerText={headerText} backBtnInfo={backBtnInfo}
                            minimized={minimized} canClose={canClose}>
            {children}
        </ModalcontainerView>
    )
})