import cool from '../assets/images/emojies/simple/cool.png';
import clap from '../assets/images/emojies/simple/clap.png';
import evilFace from '../assets/images/emojies/simple/evil-face.png';
import like from '../assets/images/emojies/simple/like.png';
import shocked from '../assets/images/emojies/simple/shocked.png';
import wow from '../assets/images/emojies/simple/wow.png';
import angry from '../assets/images/emojies/simple/angry.png';
import mindBlow from '../assets/images/emojies/simple/mind-blow.png';
import lovely from '../assets/images/emojies/simple/lovely.png';

export const emojis = () =>  ([
    {
        id: 'E1',
        imgPath: cool
    },
    {
        id: 'E2',
        imgPath: clap
    },
    {
        id: 'E3',
        imgPath: evilFace
    },
    {
        id: 'E4',
        imgPath: like
    },
    {
        id: 'E5',
        imgPath: shocked
    },
    {
        id: 'E6',
        imgPath: wow
    },
    {
        id: 'E7',
        imgPath: angry
    },
    {
        id: 'E8',
        imgPath: mindBlow
    },
    {
        id: 'E9',
        imgPath: lovely
    },
])