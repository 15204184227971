import { Duration, intervalToDuration } from "date-fns";
import { LatLngExpression } from "leaflet";
import { I3Model } from "../components/Map3d/components/model";

export const isValidEmail = (email: string) => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isValidPass = (pass: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])(?=.{8,})/.test(pass);

export const isNumber = (val: string) => /^[0-9]+$/.test(val);

export const getQueryFromLocation = () => new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: any) => searchParams.get(prop),
});

export const generateWebsiteApprovalDeepLink = (websiteURL: string) => {
  return `metamask://approve?requestingSite=${encodeURIComponent(websiteURL)}`;
};

export const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

export const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }
};

export const getRandomInt = (min: any, max: any) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min)) + min;
};

export const usersMostVolumeLevel = (arr: []) => {
  return arr.filter(
    (prev: any, current) => prev.level > 6 ? prev : current,
  );
}

export const sortArrayByName = (x: any, y: any) => {
  if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1; }
  if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1; }
  return 0;
}

export const microphoneOptionGenerate = (id: number, status: string, roomId: number) => {
  return {
    "user_public_id": id,
    "room_public_id": roomId,
    "event": status
  };
}

export const capitalize = (string: string) => string.toString()[0].toUpperCase() + string.toString().slice(1);

const compare = (a: any, b: any, value: any) => {
  const nameA = a.username?.toLowerCase();
  const nameB = b.username?.toLowerCase();

  let comparison = 0;

  if (nameA.indexOf(value) > nameB.indexOf(value)) {
    comparison = 1;
  } else if (nameA.indexOf(value) < nameB.indexOf(value)) {
    comparison = -1;
  }

  return comparison;
}

export const customSort = (data: any, value: string) => {
  return data
    .filter((user: any) => (user.username.toLowerCase()).includes(value.toLowerCase()))
    .sort((a: any, b: any) => compare(a, b, value));
}

export const downloadFile = (url: string, filename: string) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}

export const getVideoDimensions = (videoElement: any) => {
  return new Promise((resolve, reject) => {
    videoElement.addEventListener("loadedmetadata", () => {
      resolve({ width: videoElement.videoWidth.toString(), height: videoElement.videoHeight.toString(), videoElement });
    });

    videoElement.addEventListener("error", (e: any) => {
      console.warn("ERROR: ", e.message);
      reject(e);
    });
  });
};

export const toHHMMSS = (secs: any) => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum % 3600) / 60);
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(v => v.toString().padStart(2, '0'))
    .join(':');
};

export const replaceKey = (obj: any, oldKey: string, newKey: string) => {
  const updatedObj: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    const updatedKey = key === oldKey ? newKey : key;
    updatedObj[updatedKey] = value;
  });
  return updatedObj;
}

export const sizeInMB = (sizeInBytes: any) => (sizeInBytes / (1024 * 1024)).toFixed(2);

export const ipfsUriToUrl = (str: string) => {
  const baseGatewayUrl = "https://ipfs.bahamut.io/ipfs/";
  if (str.includes("ipfs://")) {
    const ipfsHash = str.replace("ipfs://", "");
    return baseGatewayUrl + ipfsHash;
  } else {
    return str;
  }
};

export const calcMapCoordinates = (coordinates: any): LatLngExpression[] => {
  let west = coordinates[0];
  let east = coordinates[0];
  let north = coordinates[0];
  let south = coordinates[0]
  for (let coord of coordinates) {
    if (coord[0] > north[0]) {
      north = coord
    }
    if (coord[0] < south[0]) {
      south = coord
    }
    if (coord[1] < west[1]) {
      west = coord
    }
    if (coord[1] > east[1]) {
      east = coord
    }
  }
  return [west, east, north, south] as LatLngExpression[]
}

export const removeElement = (array: any, from: any, to?: any) => {
  if (from > array.length - 1) return array
  let rest = array.slice((to || from) + 1 || array.length);
  array.length = from < 0 ? array.length + from : from;
  return array.push.apply(array, rest);
};

export const convert = (numberWithDecimal: number) => Math.round(numberWithDecimal * 10) / 10;

export const genderStringify = ["", "Male", "Female"]
export const getFileSize = (num: number): number => {
  let size = `${num / (1024 * 1024)}`;
  return Number(size.slice(0, 5))
};

export const truncateText = (text: string = "", length: number) => {
  if (typeof text === "string" && text.length <= length) {
    return text;
  }

  return text.slice(0, length) + '\u2026'
}


export const calculateTimeUntil = (targetDtae: Date | number, joinType: string = ' / '): string => {
  const duration: Duration = intervalToDuration({
    start: new Date(),
    end: new Date(targetDtae)
  })
  return formatDuration(duration, joinType)
}

const formatDuration = (duration: Duration, joinType: string): string => {
  const parts = [];
  if (duration.years && duration.years > 0) parts.push(`${duration.years}y`)
  if (duration.months && duration.months > 0) parts.push(`${duration.months}m`)
  if (duration.days && duration.days > 0) parts.push(`${duration.days}d`)
  if (duration.hours && duration.hours > 0) parts.push(`${duration.hours}h`)
  if (duration.minutes && duration.minutes > 0) parts.push(`${duration.minutes}min`)
  if (duration.seconds && duration.seconds > 0) parts.push(`${duration.seconds}sec`)
  return parts.join(joinType)
}

export const getExpectedDate = (min: number): Date => {
  return new Date(new Date().setSeconds(new Date().getSeconds() + min * 60))
}


const api = 'https://fv-media.storage.googleapis.com/'
export const transformModelData = (data: any): I3Model[] => {
  return data.map((item: any) => {
    return {
      path: api + item.model,
      position: {
        x: item.position_x,
        y: item.position_y,
        z: item.position_z,
      },
      scale: {
        x: item.scale_x,
        y: item.scale_y,
        z: item.scale_z,
      },
      rotation: {
        x: item.rotation_x,
        y: item.rotation_y,
        z: item.rotation_z,
      },
      public_id: item.id
    }
  })
}

export const SECONDS_TO_MILLISECONDS = 1000;
export const MINUTES_TO_MILLISECONDS = 60 * SECONDS_TO_MILLISECONDS;
export const HOURS_TO_MILLISECONDS = 60 * MINUTES_TO_MILLISECONDS;
export const DAYS_TO_MILLISECONDS = 24 * HOURS_TO_MILLISECONDS;

export const getKickUserTime = (time: string) => {
  const specificDate: any = new Date(time);
  const currentDate: any = new Date();
  const differenceInMs = specificDate - currentDate;
  let timeLeft;

  if (!!time) {
    timeLeft = {
      days: Math.floor(differenceInMs / DAYS_TO_MILLISECONDS),
      hours: Math.floor((differenceInMs / HOURS_TO_MILLISECONDS) % 24),
      minutes: Math.floor((differenceInMs / MINUTES_TO_MILLISECONDS) % 60),
      seconds: Math.floor((differenceInMs / SECONDS_TO_MILLISECONDS) % 60),
    };
    return timeLeft;
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
}


export const generateMetamaskMessage = (account: string,nonce:string, domain:string) => {
  return (`${domain} wants you to sign in with your Ethereum account:\n${account}\n\nI accept the Cerebrum Terms of Service: ${domain}/terms\n\nURI: ${domain}\nVersion: 1\nChain ID: 5165\nNonce: ${nonce}\nIssued At: ${new Date().toISOString()}`)
}

export const generateDeepLink = (id:string, token:string | null)=> {
  const defaultUrl = "https://pandamr.page.link/?link=";
  const secondaryUrl = "&apn=io.pandamr.metamobile&isi=6443733758&ibi=io.pandamr.metamobile&efr=1";
  const encodedUrl = encodeURIComponent(`https://pandamr.io/dashboard?command=LoginAndOpenRoom&roomId=${id}&userToken=${token}`);
  const link = String(defaultUrl + encodedUrl + secondaryUrl);
  return link;
}