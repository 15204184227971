import {makeAutoObservable} from "mobx";
import {cacheService} from "../../services/cacheService";

class QualityStore {
    quality: number = Number(cacheService.get("quality")) || 1;
    showQualityPopup: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    setQuality = (quality: number) => {
        cacheService.set("quality", `${quality}`)
        this.quality = quality;
        this.setShowQualityPopup(false);
    }

    setShowQualityPopup = (state: boolean) => {
        this.showQualityPopup = state;
    }
}

export const qualityStore = new QualityStore()