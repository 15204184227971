import { Suspense, useEffect } from "react";
import { routes } from "./routes/routes";
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { route } from "./types/global.types";
import { globalStore } from "./global.store";
import Toast from "./components/toast";
import { observer } from "mobx-react-lite";
import Modalcontainer from "./components/modalcontainer";
import { Notification } from "./components/modalcontainer/bodyContent/notification";
import ScrollToTop from "./helpers/scroll_to_top";
import { Approved } from "./components/modalcontainer/bodyContent/approved";
import { OtherContentModal } from "./components/otherContentModal";
import TicketUpload from "./components/TicketUpload";
import {ticketupload} from "./components/TicketUpload/ticketupload.store";
import EditAvatar from "./components/editAvatar";
import {editAvatarStore} from "./components/editAvatar/editAvatar.store";
import AvatarEditor from "./components/avatarEditor";
import {authorizationStore} from "./modules/authorization/authorization.store";
import {cardsblockStore} from "./components/cardsblock/cardsblock.store";
import OpenInApp from "./components/openInApp";


const App = observer(() => {
    const {
        notification,
        notificationContent,
        otherContentModal,
        guestPopupState,
        setGuestPopupState,
        setNavigation,
        projectConfiguration
    } = globalStore;
    const {eventData} = ticketupload;
    const {openInAppData} = cardsblockStore;
    const {showEditAvatarPopup, avatarEditor} = editAvatarStore;
    const navigate = useNavigate()
    useEffect(() => {
        projectConfiguration();
        setNavigation(navigate)
    }, [])

    return (
        <Suspense fallback={null}>
            <ScrollToTop />
            <Routes>
                {routes.map((item: route) => (
                    <Route key={item.id} element={item.component && <item.component />} path={item.path}>
                        {
                            item.child && item.child.map((child) => (
                                <Route key={child.id} path={child.path} element={child.component && <child.component />} />
                            ))
                        }
                    </Route>
                ))
                }
                <Route path="" element={<Navigate to="/" replace />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            {/* {!userData.public_id && <FullScreenLoader />} */}
            <Toast />
            {notification &&
                <Modalcontainer backBtn={false} closePopup={notificationContent.action as Function}>
                    <Notification button={notificationContent.button} headerText={notificationContent.headerText}
                        action={notificationContent.action} time={notificationContent.time} />
                </Modalcontainer>}
            {guestPopupState &&
                <Modalcontainer backBtn={false} closePopup={() => {
                    setGuestPopupState(false);
                    authorizationStore.redirectUrl = "";
                }}>
                    <Approved accept={{
                        text: 'Sign Up',
                        color: 'primary'
                    }} decline={{
                        text: 'Sign In',
                        color: 'secondaryLight'
                    }} declineAction={() => {
                        navigate("?auth=login")
                        setGuestPopupState(false)
                    }} acceptAction={() => {
                        navigate("?auth=registration")
                        setGuestPopupState(false)
                    }} headerText={{
                        title: "Sign In or Sign Up to continue!",
                    }} />
                </Modalcontainer>}
            {/* <WebSocketComponent token={cacheService.get("auth_token")} /> */}
            {otherContentModal && <OtherContentModal />}
            {eventData?.eventType === 2 && <TicketUpload />}
            {showEditAvatarPopup && <EditAvatar/>}
            {!!avatarEditor && <AvatarEditor/>}
            {!!openInAppData && <OpenInApp/>}
        </Suspense>
    );
})

export default App;
