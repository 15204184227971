import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {WagmiWrp} from './components/WagmiWrp';
import "./assets/styles/styles.scss"
import {HelmetProvider} from "react-helmet-async";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <WagmiWrp>
            <HelmetProvider context={{}}>
                <App/>
            </HelmetProvider>
        </WagmiWrp>
    </BrowserRouter>
);

reportWebVitals();
