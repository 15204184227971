
import { makeAutoObservable } from 'mobx';
import { sendMessageStore } from '../../modules/unityLayout/sendMessage.store';
import { apiServiceV2 } from '../../services/apiService';
import { globalStore } from '../../global.store';
import { unityLayoutStore } from '../../modules/unityLayout/unityLayout.store';

class Unityfooter {
    uploadPopupStatus: boolean = false;
    hooryPopupStatus: boolean = false;
    showModelController: boolean = false;
    constructor() {
        makeAutoObservable(this)
    }

    setUploadPopupStatus = (status: boolean) => {
        if (status) sendMessageStore.sendMessage("ReactManager", "RequestNearestTransform");
        sendMessageStore.sendMessage("ReactManager", "LockKeyboardInput", `${!status}`)
        this.uploadPopupStatus = status
    }

    setHooryPopupStatus = (status: boolean) => {
        if (status) sendMessageStore.sendMessage("ReactManager", "RequestNearestTransform");
        sendMessageStore.sendMessage("ReactManager", "LockKeyboardInput", `${!status}`)
        this.hooryPopupStatus = status
    }

    addHooryToken = async (token: string) => {
        const response = await apiServiceV2.changeRoomHooryToken({ hooryWebsiteToken: token })
        if (!response.error) {
            this.setHooryPopupStatus(false)
            globalStore.setToasts(true, 'added_hoory_token')
            unityLayoutStore.setHooryToken(token)
            //@ts-ignore
            window.hoorySDK.reRun({
                websiteToken: token,
                baseUrl: "https://app.hoory.com"
            });
        }
    }

    deleteMaket = () => {
        sendMessageStore.sendMessage("ReactManager", "DeleteSelected");
    }

    setShowModelController = (state: boolean) => {
        sendMessageStore.sendMessage("ReactManager", "EnableDrag", `${!state}`);
        this.showModelController = state;
    }

    removeState = () => {

    }
}
export const unityfooterStore = new Unityfooter();

