import {makeAutoObservable} from "mobx";

class EditProfileStore {
    showEditProfilePopup: boolean = false;
    constructor() {
        makeAutoObservable(this)
    }

    setShowEditProfilePopup  = (state: boolean) => {
        this.showEditProfilePopup = state;
    }
}

export const editProfileStore = new EditProfileStore()